import { QueryClient } from '@tanstack/react-query';
import snakeToCamelCase from 'services/utils/convert-object-keys-snake-to-camel';
import { LS_OZMO_TOKEN } from 'scenes/google-auth';
import { isDefined } from 'services/utils/type-guards/generic';

import Collection from './collection';
import ContentEntry from './content-entry';
import ContentType from './content-type';
import HistoryEvent from './history-event';
import Language from './language';
import Locale from './locale';
import LocalizedCollection from './localized-collection';
import LocalizedContentEntry from './localized-content-entry';
import MediaEntry from './media-entry';
import Space from './space';
import Topic from './topic';
import Device from './device';
import DeviceType from './device-type';
import Manufacturer from './manufacturer';
import OperatingSystem from './operating-system';
import OperatingSystemRelease from './operating-system-release';
import OperatingSystemVersion from './operating-system-version';
import DeviceShell from './device-shell';
import ClientLocale from './client-locale';
import Client from './client';
import { UnsuccessfulResponseError } from './errors';
import { isHttpMethod } from './http_method';

const getAuthToken = () => localStorage.getItem(LS_OZMO_TOKEN);
const delAuthToken = () => localStorage.removeItem(LS_OZMO_TOKEN);

/**
 * Do an HTTP request against the Ozmo API.
 *
 * Adds Authorization and X-Api-Key headers to all requests, and conditionally
 * adds a Content-Type header when 'stringifyBody' is true (the default).
 *
 * In the successful case of a response status code in the range [200, 300),
 * returns the Response object from the fetch API with no other post-processing.
 * If the status code is outside [200, 300), throws an `UnsuccessfulResponseError`
 * which will **consume the response body stream** to try to inspect error
 * information returned by the API.
 *
 * @param resource - the path to a resource relative to an API base URL (e.g. 'authoring/content_entries'), or a complete HTTP/HTTPS URL.
 * @param body - (optional) an optional request body (default: none)
 * @param method - (optional) request method (default: delegated to `fetch`, i.e. 'GET')
 * @param headers - (optional) additional request headers
 * @param stringifyBody - serialize the 'body' argument with `JSON.stringify` (default: true)
 * @returns the `Response` from `fetch`
 */
const ozmoApiRequestRaw = async (
  resource: string,
  body?: BodyInit | null,
  method?: string,
  headers?: Record<string, string>,
  stringifyBody: boolean = true
): Promise<Response> => {
  // verify the http method argument if given; undefined is allowed by 'fetch'.
  if (isDefined(method) && !isHttpMethod(method)) {
    // should probably use `new Error(...)`, but this matches the existing behavior of 'ozmoApiRequest'
    throw 'Invalid HTTP verb';
  }

  // perceive thy token of authorization
  const token = getAuthToken();
  if (!token) {
    throw { code: 401, message: 'Not logged in' };
  }

  const requestUrl = urlForResource(resource);

  // would prefer using `new Headers(...)` here, so the argument type
  // could be HeadersInit instead of just Record<string, string>, but
  // it makes testing with Jest matchers on fetch arguments a pain because
  // there's no built in matcher that can instrospect a Headers object.
  const requestHeaders: Record<string, string> = {
    ...headers,
    ...getAuthHeaders(token),
  };

  let requestBody = body;

  if (body && stringifyBody) {
    requestHeaders['Content-Type'] = 'application/json';
    requestBody = JSON.stringify(body);
  }

  const response = await fetch(requestUrl, {
    headers: requestHeaders,
    method: method,
    body: requestBody,
  });

  // success case
  if (response.ok) {
    return response;
  }

  // error case
  const error = await UnsuccessfulResponseError.fromResponse(response);
  // if the response was not a 2xx and the body is JSON with a 'code' field and a value of 401,
  // then we need to clear our locally stored auth token as a side effect.
  if (error.isCode401) {
    delAuthToken();
  }
  throw error;
};

/**
 * Do an HTTP request against the Ozmo API and deserialize the response as JSON.
 *
 * Converts deserialized object keys from snake_case to camelCase.
 *
 * @param {String} resource - The path to the resource (e.g.: "authoring/content_entries")
 * @param {Object} body - The HTTP body
 * @param {String} method - the HTTP method verb (e.g.: 'POST')
 * @param {Object} customHeaders - (Optional) Define any manual headers that aren't included in the base request header.
 * @returns A Promise that will resolve to a dictionary with the HTTP response (status, etc)
 *  and the data requested (converted to camelCase)
 */
const ozmoApiRequest = async (
  resource: string,
  body: any,
  method: string,
  withResponse: boolean = false,
  stringifyBody: boolean = true,
  customHeaders?: any
): Promise<any | { data: any; response: Response }> => {
  try {
    const response = await ozmoApiRequestRaw(
      resource,
      body,
      method,
      customHeaders,
      stringifyBody
    );

    // If status code is 204 (No Content), there will be no body So the respose.json() call could
    // fail, but the actual ozmo api call succeeded So instead just return the response as the data
    // so the user has access to the status code and headers and whatnot
    if (response.status === 204) {
      return response;
    }
    const json = await response.json();
    const camelCasedJson = snakeToCamelCase(json);
    return withResponse ? { data: camelCasedJson, response } : camelCasedJson;
  } catch (error) {
    if (error instanceof UnsuccessfulResponseError) {
      // For anything other than a 200-level response we will have this error,
      throw withResponse
        ? { data: error.json, response: error.response }
        : error.json;
    } else {
      // for anything else, just re-throw
      throw error;
    }
  }
};

const urlForResource = (resource: string): string => {
  // if the resource string starts with a scheme, assume it's a fully-qualified URL and return it
  // unmodified instead of prepending the API's global base URL
  const hasScheme = resource.match(/^https?\/\//);
  if (hasScheme !== null) {
    return resource;
  }

  // remove any number of forward slashes from the resource string
  const trimmedResource = resource.replace(/^\/+/, '');

  // interpolate the configured API base URL
  const baseUrl = import.meta.env.VITE_APP_OZMO_API_URL;
  return `${baseUrl}/${trimmedResource}`;
};

const getAuthHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  'X-Api-Key': `${import.meta.env.VITE_APP_OZMO_X_API_KEY}`, // env var can be undefined, so interpolate it
});

// The queryClient for the app
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});

export type OzmoApiContext = {
  queryClient: QueryClient;
  Collection: typeof Collection;
  ContentEntry: typeof ContentEntry;
  ContentType: typeof ContentType;
  DeviceShell: typeof DeviceShell;
  HistoryEvent: typeof HistoryEvent;
  Language: typeof Language;
  Locale: typeof Locale;
  LocalizedCollection: typeof LocalizedCollection;
  LocalizedContentEntry: typeof LocalizedContentEntry;
  MediaEntry: typeof MediaEntry;
  Space: typeof Space;
  Topic: typeof Topic;
  ClientLocale: typeof ClientLocale;
  Client: typeof Client;
  Device: typeof Device;
  DeviceType: typeof DeviceType;
  Manufacturer: typeof Manufacturer;
  OperatingSystem: typeof OperatingSystem;
  OperatingSystemRelease: typeof OperatingSystemRelease;
  OperatingSystemVersion: typeof OperatingSystemVersion;
};

const ozmoApi: OzmoApiContext = {
  queryClient,
  // Implemented models.
  Collection,
  ContentEntry,
  ContentType,
  DeviceShell,
  HistoryEvent,
  Language,
  Locale,
  LocalizedCollection,
  LocalizedContentEntry,
  MediaEntry,
  Space,
  Topic,
  ClientLocale,
  Client,
  Device,
  DeviceType,
  Manufacturer,
  OperatingSystem,
  OperatingSystemRelease,
  OperatingSystemVersion,
};

export default ozmoApi;
export { ozmoApiRequest, ozmoApiRequestRaw, queryClient };
