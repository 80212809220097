import { FunctionComponent } from 'react';
import {
  Filter1,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  PostAdd,
  SwitchAccount,
} from '@mui/icons-material';
import { Editable, Slate } from 'slate-react';
import { Box, ClickAwayListener, Divider, styled } from '@mui/material';

import { FormatButton, LinkButton, BlockButton, Toolbar } from './components';
import { useRichTextEditor } from './use-rich-text-editor';
import InsertTutorialButton from './components/tutorial-button';
import InsertTutorialChoiceButton from './components/choice-button';
import InsertStepHeaderButton from './components/step-header-button';

const StyledEditable = styled(Editable)`
  border: 1px solid var(--color-neutral-four);
  background-color: var(--color-neutral-one);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: ${({ theme }) => theme.spacing(1)};
  p:first-of-type {
    margin-block-start: 0;
  }
  p:last-of-type {
    margin-block-end: 0;
  }
`;

type Props = {
  initialValue: string;
  onSave: (text: string) => void;
  autoFocus?: boolean;
  fullMarkdown?: boolean;
};

const RichTextEditor: FunctionComponent<Props> = ({
  initialValue,
  onSave,
  autoFocus = true,
  fullMarkdown = false,
}) => {
  const {
    editor,
    value,
    renderElement,
    renderLeaf,
    handleEditorKeydown,
    handleClickAway,
  } = useRichTextEditor(initialValue, onSave);

  return (
    <Slate editor={editor} value={value as any}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box flex="auto">
          <Toolbar>
            <FormatButton format="bold" tooltip="Bold (ctrl+b)">
              <FormatBold />
            </FormatButton>
            <FormatButton format="italic" tooltip="Italic (ctrl+i)">
              <FormatItalic />
            </FormatButton>
            <BlockButton
              format="bulleted-list"
              tooltip="Add bulleted list (ctrl+l)"
            >
              <FormatListBulleted />
            </BlockButton>
            <LinkButton />
            {fullMarkdown && (
              <>
                <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                <InsertStepHeaderButton tooltip="Add Step Header">
                  <Filter1 />
                </InsertStepHeaderButton>
                <InsertTutorialButton tooltip="Embed Tutorial">
                  <PostAdd />
                </InsertTutorialButton>
                <InsertTutorialChoiceButton tooltip="Insert Tutorial Choice">
                  <SwitchAccount />
                </InsertTutorialChoiceButton>
              </>
            )}
          </Toolbar>
          <StyledEditable
            autoFocus={autoFocus}
            data-testid="rich-text-editor:slate-editor"
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onKeyDown={handleEditorKeydown}
          />
        </Box>
      </ClickAwayListener>
    </Slate>
  );
};

export default RichTextEditor;
