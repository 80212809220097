//
export enum Types {
  TUTORIAL = 1,
  TOUR = 2,
  COLLECTION = 3,
  LEGACY_TUTORIAL = 4,
  LEGACY_POI = 5,
  NEW_TOUR = 6,
  EXTERNAL_WEBPAGE = 7,
  STATIC_WORKFLOW = 8,
}
