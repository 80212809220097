import { FCWithChildren } from 'types/fc-with-children';
import { RenderElementProps } from 'slate-react';
import { LinkElement } from 'types/slate';

import TutorialEmbed from './tutorial-embed';
import ChoiceGroup from './choice-group';
import StepHeader from './step-header';

const Element: FCWithChildren<RenderElementProps> = (props) => {
  const { attributes, children, element } = props;
  switch ((element as any).type) {
    case 'tutorial-embed':
      return <TutorialEmbed {...(props as any)} />;
    case 'choice':
      return <ChoiceGroup {...(props as any)} />;
    case 'step-header':
      return <StepHeader {...(props as any)} />;
    case 'bulleted-list':
      return (
        <ul style={{ listStyle: 'disc' }} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'link':
      return (
        <a href={(element as LinkElement).url} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
