import { useState } from 'react';
import {
  Box,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { uniqueId } from 'lodash';
import LoadingIndicator from 'components/loading-indicator';

import EventRow from './event-row';
import { useAnswerHistory } from './use-answer-history';

const StyledAnswerHistoryTableContainer = styled(TableContainer)`
  border: none;
  border-radius: 0;
  max-height: calc(100vh - 350px);
  overflow: auto;

  .MuiTableRow-head > th {
    padding: 0.5em 2.5em;
    border-bottom: none;
  }

  .MuiTableRow-root > td {
    padding: 1.25em 2.5em;
    color: var(--color-neutral-seven);
    background-color: var(--color-neutral-one);
    border-bottom: 1px solid var(--color-neutral-three);

    p,
    .MuiSvgIcon-root {
      color: var(--color-neutral-seven);
    }

    &:nth-child(1) {
      min-width: 15em;
    }
    &:nth-child(3),
    &:nth-child(4) {
      min-width: 17em;
    }
  }

  .MuiTableFooter-root {
    position: sticky;
    bottom: 0;
    z-index: 1;

    .MuiTableRow-root > td {
      border-top: 1px solid var(--color-neutral-three);
    }
  }
`;

const AnswerHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, history } = useAnswerHistory();

  const ROWS_PER_PAGE = 50;

  return (
    <Box sx={{ py: '3em', px: '1em' }}>
      <StyledAnswerHistoryTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Contributor</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="answer-history-table-body">
            {isLoading && (
              <TableRow data-testid="answer-history:loading">
                <TableCell colSpan={4} align="center">
                  <LoadingIndicator message="Loading history" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              history
                .slice(
                  (currentPage - 1) * ROWS_PER_PAGE,
                  currentPage * ROWS_PER_PAGE
                )
                .map((historyEvent) => (
                  <EventRow key={uniqueId()} historyEvent={historyEvent} />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Box display="flex" justifyContent="center">
                  <Pagination
                    count={Math.ceil(history.length / ROWS_PER_PAGE)}
                    page={currentPage}
                    onChange={(_, newPage) => {
                      setCurrentPage(newPage);
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </StyledAnswerHistoryTableContainer>
    </Box>
  );
};

export default AnswerHistory;
