import { useEffect, useMemo, useReducer, useRef } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useAppToast } from 'contexts/app-toast-context';
import SendIcon from '@mui/icons-material/Send';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useActionModal } from 'components/modals';
import { OzmoApiContext } from 'services/ozmo-api';
import { AppToastAction } from 'contexts/app-toast-context/app-toast-context';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import {
  recursiveJobRequest,
  getSuccessCount,
} from 'services/ozmo-api/utils/recursive-job-request';
import { Attributes } from 'scenes/add-content/modal-body';
import { useOzmoAuthUser } from 'scenes/google-auth';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import {
  contentEntryToAttributes,
  isListEqual,
} from 'components/duplicate-content-entry-dialog/utils';
import { useContentEntry } from 'services/hooks/api-hooks';
import { SelectedAttributes } from 'components/attribute-selector';
import { KEY_MAP } from 'components/duplicate-content-entry-dialog/hooks';

import { ModalContent } from './modal-content';
import { reducer, InitialState, SendToMethod } from './state';

const isPresent = (it: null | undefined | any): boolean =>
  it !== null && it !== undefined;

const handleToast = (
  response: BulkOperationJobResponse,
  contentEntryIds: number[],
  destCollectionId: number,
  expected: number,
  showToast: (toast: AppToastAction) => void
) => {
  const successCount = getSuccessCount(response);
  const plural = contentEntryIds.length > 1;
  if (successCount === expected) {
    // all additions succeeded
    showToast({
      level: 'success',
      message: `Success! Your ${
        plural ? 'answers were' : 'answer was'
      } sent to the collection.`,
      linkText: 'Go to collection',
      linkPath: generateCollectionPath(destCollectionId),
    });
    return true;
  } else {
    // at least one addition failed
    showToast({
      level: 'error',
      message: `Sorry! We were unable to send your ${
        plural ? 'answers' : 'answers'
      } to the collection. Please try again.`,
      // TODO: if possible would like the option to click on the toast to retry
    });
    return false;
  }
};

// cargo-culted from:
// useCategoryHeader -> handleAddReferencesToCategory
// useAddToCategoryAction -> handleConfirm
const executeSendUnchangedCopies = async (
  api: OzmoApiContext,
  showToast: (toast: AppToastAction) => void,
  contentEntryIds: number[],
  destCollectionId: number,
  destCategoryIds: number[]
): Promise<boolean> => {
  const addOperations = destCategoryIds.map((catId) =>
    assembleBulkOperation(catId, 'add', 'content_entry_ids', contentEntryIds)
  );

  const { job } = await api.Collection.bulkAsync(
    destCollectionId,
    addOperations
  );

  const response = await api.Collection.refetchedOperationAsync(
    () => recursiveJobRequest<BulkOperationJobResponse>(job.toString()),
    destCollectionId
  );

  const toastResult = handleToast(
    response,
    contentEntryIds,
    destCollectionId,
    addOperations.length,
    showToast
  );
  return toastResult;
};

const executeSendByDuplicate = async (
  api: OzmoApiContext,
  showToast: (toast: AppToastAction) => void,
  contentEntryIds: number[],
  destCollectionId: number,
  destCategoryIds: number[],
  steptextOnly: boolean = false,
  attributes: Partial<SelectedAttributes>,
  userId: number,
  spaceId: number
): Promise<boolean> => {
  //Bulk duplicate content entries
  const duplicateOperations = contentEntryIds.map((ceId) =>
    assembleBulkOperation(ceId, 'copy_to_categories', '', [
      steptextOnly,
      { ...attributes, spaceIds: [spaceId] } as any,
      userId,
      destCategoryIds,
    ])
  );

  const { job } = await api.ContentEntry.bulkAsync(duplicateOperations);

  const response = await recursiveJobRequest<BulkOperationJobResponse>(
    job.toString()
  );
  const toastResult = handleToast(
    response,
    contentEntryIds,
    destCollectionId,
    duplicateOperations.length,
    showToast
  );
  return toastResult;
};

export const useSendToCollectionModal = (
  contentEntryIds: number[],
  sourceCollectionId: number
): [JSX.Element, () => void] => {
  const api = useOzmoApiService();
  const user = useOzmoAuthUser();
  const { contentEntry } = useContentEntry(contentEntryIds[0]);
  const [state, dispatch] = useReducer(reducer, InitialState);
  const showToast = useAppToast();
  const steptextOnlyCheckboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch({
      type: 'ChangeSelectedSpaceId',
      value: contentEntry?.spaceId ?? 0,
    });
  }, [contentEntry]);

  const initialAttributes = useMemo(() => {
    if (contentEntryIds.length === 1) {
      return contentEntryToAttributes(contentEntry);
    }
    return {} as Attributes;
  }, [contentEntry, contentEntryIds]);

  const submitButtonText =
    state.sendToMethod === SendToMethod.ByDuplicate
      ? !state.duplicateSet
        ? 'Next'
        : 'Send to collection'
      : 'Send to collection';

  const submitButtonIcon =
    state.sendToMethod === SendToMethod.ByDuplicate ? (
      !state.duplicateSet ? (
        <ArrowForwardIcon />
      ) : (
        <SendIcon />
      )
    ) : (
      <SendIcon />
    );

  const canDuplicate = useMemo(() => {
    if (state.destCategoryIds.length < 1) return false;
    if (contentEntry?.spaceId !== state.selectedSpaceId) {
      return true;
    } else if (contentEntryIds.length > 1) {
      return true;
    }
    if (!initialAttributes || !state.attributes) return false;

    const allAttributesEqual = Object.entries(KEY_MAP).every(
      ([initialKey, attributeKey]) => {
        return isListEqual(
          initialAttributes[initialKey as keyof Attributes],
          state.attributes?.[attributeKey] ?? []
        );
      }
    );

    return !allAttributesEqual;
  }, [
    state.destCategoryIds.length,
    state.selectedSpaceId,
    state.attributes,
    contentEntry?.spaceId,
    contentEntryIds.length,
    initialAttributes,
  ]);

  const submitButtonEnabled =
    isPresent(state.destCollectionId) &&
    isPresent(state.sendToMethod) &&
    state.destCategoryIds.length > 0;

  const handleSubmitButton = async () => {
    dispatch({
      type: 'ChangeSent',
      value: true,
    });
    switch (state.sendToMethod) {
      case SendToMethod.ByReference: {
        // not dispatching an action here because
        // (1) useActionModal is handling loading state for us
        // (2) I don't know how to get error info if the bulk operation fails
        // but in theory you can do something like:
        //   dispatch(submitting);
        //   const result = await runTheJob();
        //   if (result.fail) { dispatch(error); }
        // the reducer itself can't be async (without middleware)
        await executeSendUnchangedCopies(
          api,
          showToast,
          contentEntryIds,
          state.destCollectionId!,
          state.destCategoryIds
        );
        break;
      }
      case SendToMethod.ByDuplicate: {
        if (!state.duplicateSet) {
          dispatch({
            type: 'ChangeDuplicateSet',
            value: true,
          });
        } else {
          await executeSendByDuplicate(
            api,
            showToast,
            contentEntryIds,
            state.destCollectionId!,
            state.destCategoryIds,
            steptextOnlyCheckboxRef.current?.checked,
            state.attributes ?? {},
            user?.id ?? 0,
            state.selectedSpaceId ?? 0
          );
        }
        break;
      }
    }
  };

  const entriesPlural = contentEntryIds.length > 1;
  const ptxtAnswer = entriesPlural ? 'multiple answers' : 'answer';
  const modalTitleText = `Send ${ptxtAnswer} to a collection`;

  const closeOnSubmit =
    state.sendToMethod === SendToMethod.ByReference ||
    (state.sendToMethod === SendToMethod.ByDuplicate && state.duplicateSet);
  const { modal, openModal } = useActionModal({
    modalContent: (
      <ModalContent
        sourceCollectionId={sourceCollectionId}
        contentEntryIds={contentEntryIds}
        state={state}
        dispatch={dispatch}
        initialAttributes={initialAttributes ?? ({} as Attributes)}
        selectedSpaceId={state.selectedSpaceId ?? 0}
        steptextOnlyCheckboxRef={steptextOnlyCheckboxRef}
      />
    ),
    modalProps: {
      title: modalTitleText,
      confirmButtonText: submitButtonText,
      confirmButtonEndIcon: submitButtonIcon,
      disableConfirmButton:
        (state.duplicateSet && !canDuplicate) || !submitButtonEnabled,
      maxWidth: 'md',
    },
    onConfirm: handleSubmitButton,
    disableConfirmButtonOnClick: state.sent,
    closeOnConfirm: closeOnSubmit,
  });

  return [modal, openModal];
};
