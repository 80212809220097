import { Chip, Typography } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';
import StatusIndicator from 'components/status-indicator';

import { getDisplayNames, applyTextRules, parsePath } from '../util';
import {
  ContentEvent,
  HistoryEvent,
  isContentChangedEvent,
  isContentRemovedEvent,
  Schema,
} from '../types';

const getActionText = (historyEvent: HistoryEvent, schema?: Schema) => {
  switch (historyEvent.action) {
    case 'create':
      return 'Created answer';
    case 'publish':
      return 'Published answer';
    case 'delete':
      return 'Deleted answer';
    case 'restore':
      return 'Restored answer';
    case 'destroy':
      return 'Permanently deleted answer';
    case 'add_attribute':
      return 'Added attribute';
    case 'delete_attribute':
      return 'Removed attribute';
    case 'change_content':
      if (schema && 'details' in historyEvent) {
        return getContentChangeActionText(historyEvent, schema);
      }
      return historyEvent.action;
    default:
      return historyEvent.action;
  }
};

const getContentChangeActionText = (
  historyEvent: ContentEvent,
  schema: Schema
) => {
  const path = parsePath(historyEvent.details.key);
  const fieldDisplayNames = getDisplayNames(path, schema.fields);

  const label = applyTextRules(fieldDisplayNames[fieldDisplayNames.length - 1]);

  if (isContentChangedEvent(historyEvent)) {
    return `Updated ${label}`;
  }
  if (isContentRemovedEvent(historyEvent)) return `Removed ${label}`;

  return `Added ${label}`;
};

const EventAction = ({ historyEvent }: { historyEvent: HistoryEvent }) => {
  const api = useOzmoApiService();
  const { contentEntry } = useCurrentLocalizedContentEntry();
  const contentType = api.ContentType.get({ id: contentEntry?.contentTypeId });

  if (historyEvent.action === 'publish') {
    return (
      <Chip
        label="Published"
        variant="outlined"
        icon={<StatusIndicator status="published" withTooltip={false} />}
        sx={{ pl: '0.5em' }}
        data-testid="history-event-action"
      />
    );
  }

  const schema = contentType.data?.contentSchema;

  if (!schema) return null;

  return (
    <Typography fontWeight="bold" data-testid="history-event-action">
      {getActionText(historyEvent, schema)}
    </Typography>
  );
};

export default EventAction;
