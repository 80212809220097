import { FunctionComponent, ReactNode } from 'react';

import { useTutorialEmbedButton } from './hooks';
import BaseButton from './base-button';

type Props = {
  tooltip: string;
  children: ReactNode;
};

const InsertTutorialButton: FunctionComponent<Props> = ({
  tooltip,
  children,
}) => {
  const { color, backgroundColor, handleClick } = useTutorialEmbedButton();

  return (
    <BaseButton
      color={color}
      backgroundColor={backgroundColor}
      label={'Embed Tutorial'}
      tooltip={tooltip}
      onClick={handleClick}
    >
      {children}
    </BaseButton>
  );
};

export default InsertTutorialButton;
