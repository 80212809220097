import { TableCell, TableRow } from '@mui/material';
import Contributor from 'components/contributor';

import { HistoryEvent } from '../types';

import EventAction from './event-action';
import EventDetails from './event-details';

const formatDate = (date: Date) => {
  const dateStr = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(date);

  const timeStr = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date);

  return `${dateStr} at ${timeStr}`;
};

const EventRow = ({ historyEvent }: { historyEvent: HistoryEvent }) => (
  <TableRow>
    <TableCell>
      <EventAction historyEvent={historyEvent} />
    </TableCell>
    <TableCell>
      <EventDetails historyEvent={historyEvent} />
    </TableCell>
    <TableCell>
      <Contributor
        email={historyEvent.user.email}
        user={`${historyEvent.user.firstName} ${historyEvent.user.lastName}`}
        picture={historyEvent.user.picture}
      />
    </TableCell>
    <TableCell>{formatDate(new Date(historyEvent.createdAt))}</TableCell>
  </TableRow>
);

export default EventRow;
