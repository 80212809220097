import { useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Translate } from '@mui/icons-material';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';
import { useActionModal } from 'components/modals';

import { useBulkTranslate } from './hooks';
import TranslateBulkModal from './modal';
import { isComplete, isInProgress, isStart } from './status';

const TranslateCollectionButton = () => {
  const { collection, language } = useCurrentCollection();
  const allContentEntries = useMemo(
    () => collection?.categories?.flatMap((cat) => cat.contentEntries) ?? [],
    [collection]
  );

  const {
    status,
    onSubmit,
    onClose,
    availableTargetLanguages,
    selectedLanguages,
    setSelectedLanguages,
  } = useBulkTranslate(allContentEntries);

  const { modal, openModal } = useActionModal({
    onRefuse: onClose,
    onConfirm: isComplete(status) ? onClose : onSubmit,
    closeOnConfirm: false,
    modalContent: (
      <TranslateBulkModal
        status={status}
        answerCount={allContentEntries.length}
        targetLanguages={availableTargetLanguages}
        onChangeTargetLanguages={setSelectedLanguages}
      />
    ),
    modalProps: {
      title: isStart(status) ? 'Translate all answers in this collection' : '',
      disableConfirmButton:
        selectedLanguages.length === 0 ||
        !(isStart(status) || isComplete(status)),
      confirmInProgress: isInProgress(status),
      confirmButtonText: isComplete(status) ? 'Close' : 'Translate',
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  return (
    <>
      <Tooltip
        arrow
        title={
          language === 'en'
            ? 'Translate collection'
            : 'Only available in English'
        }
      >
        <div>
          <Button
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            disabled={language !== 'en'}
            startIcon={<Translate />}
            onClick={openModal}
          >
            {'Translate'}
          </Button>
        </div>
      </Tooltip>
      {modal}
    </>
  );
};

export default TranslateCollectionButton;
