import { FunctionComponent, ReactNode } from 'react';

import { useTutorialChoiceGroup } from './hooks';
import BaseButton from './base-button';

type Props = {
  tooltip: string;
  children: ReactNode;
};

const InsertTutorialChoiceButton: FunctionComponent<Props> = ({
  tooltip,
  children,
}) => {
  const { color, backgroundColor, handleClick } = useTutorialChoiceGroup();

  return (
    <BaseButton
      color={color}
      backgroundColor={backgroundColor}
      label={'Tutorial Choice'}
      tooltip={tooltip}
      onClick={handleClick}
    >
      {children}
    </BaseButton>
  );
};

export default InsertTutorialChoiceButton;
