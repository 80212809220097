import { FC } from 'react';
import { Typography } from '@mui/material';
import { useContentEntry } from 'services/hooks/api-hooks';

type ContentEntryInfoProps = { id: number };

const ContentEntryInfo: FC<ContentEntryInfoProps> = ({ id }) => {
  const { contentEntry, isLoading } = useContentEntry(id);
  if (isLoading || !contentEntry) {
    return <b>{'...'}</b>;
  }

  // Goal: `*${title} (${topic})* in the *${space}* space`
  // but handle topic or space being absent.
  const title = contentEntry.title;

  const topic = contentEntry.topic?.slug;
  const topicText = topic ? (
    <>
      {' '}
      <b>{`(${topic})`}</b>
    </>
  ) : (
    ''
  );

  const space = contentEntry.space?.name;
  const spaceText = space ? (
    <>
      {' '}
      in the <b>{space}</b> space
    </>
  ) : (
    ''
  );

  return (
    <>
      <b>{title}</b>
      {topicText}
      {spaceText}
    </>
  );
};

type Props = {
  contentEntryIds: number[];
  duplicate: boolean;
};

export const Instructions: FC<Props> = ({ contentEntryIds, duplicate }) => {
  const count = contentEntryIds.length;

  if (count === 0) {
    throw new Error(
      'Tried to initialize Instructions component with no content entry IDs'
    );
  }

  return (
    <Typography data-testid="send-to-collection-dialog:instructions">
      {`You are about to send ${duplicate ? 'a duplicate of ' : ''}`}
      {count > 1 ? (
        <>
          {`${duplicate ? 'these ' : ''}`}
          <b>{`${count} answers`}</b>
        </>
      ) : (
        <ContentEntryInfo id={contentEntryIds[0]} />
      )}
      {' to another collection.'}
    </Typography>
  );
};

export default Instructions;
