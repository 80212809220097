import { extractQueryAttributes } from 'components/attribute-selector';
import { AppToastAction } from 'contexts/app-toast-context/app-toast-context';
import { format as formatDate } from 'date-fns';
import _ from 'lodash';
import { useState } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { convertAttributeMapToSearchParams } from 'scenes/content-search/hooks';
import ContentEntry from 'services/ozmo-api/content-entry';
import { isDefined } from 'services/utils/type-guards/generic';

/**
 * Extract content entry search filters from query parameters in a Location's URL
 * @param location URL/route to inspect
 * @returns ContentEntrySearchParams populated with any filters from the URL
 */
const extractSearchParams = (location: Location): ContentEntrySearchParams => {
  const attributes = extractQueryAttributes(location);
  return convertAttributeMapToSearchParams(attributes);
};

/**
 * Extract content entry search parameters from the URL of the current page/route
 * via react-router-dom's `useLocation`.
 * @returns ContentEntrySearchParams populated with any filters
 */
export const useSearchParamsFromLocation = () => {
  const location = useLocation();
  return extractSearchParams(location);
};

/**
 * Compose a suggested filename including a language short code and date/time.
 * @param language short code to include
 * @param dateTime (optional) date and time to include
 * @returns generated filename
 */
const generateExportFilename = (language: LanguageModel, dateTime?: Date) => {
  // `Search results - ${languageShortCode} - ${Date.now()}.csv`;
  const useDate = dateTime ?? new Date(Date.now());
  const datePart = formatDate(useDate, 'yyyy-MM-dd HHmm');
  return `Search results - ${language.shortCode} - ${datePart}.csv`;
};

/**
 * Closure around the temporary Object URL and Anchor element used to trigger
 * a browser "Save file" for a Blob.
 *
 * Returns two callbacks:
 * (1) execute a download/save file for the given blob. This creates an object URL
 *     and adds a temporary Anchor element to the document body.
 * (2) releases the generated Object URL and remove the Anchor element
 */
const initializeSaveFile = (): [
  (content: Blob, filename: string) => void,
  () => void
] => {
  let url: string | undefined;
  let anchor: HTMLAnchorElement | undefined;

  const execute = (content: Blob, filename: string) => {
    url = URL.createObjectURL(content);

    anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
  };

  const cleanup = () => {
    if (isDefined(url)) {
      URL.revokeObjectURL(url);
    }
    if (isDefined(anchor)) {
      anchor.remove();
    }
  };

  return [execute, cleanup];
};

/**
 * Given something resembling an error captured by a catch block, try to get a readable message.
 * ozmoApiRequest throws errors in several different shapes, and this looks for a message. If the
 * argument is a string, return it. If it's an object, see if it's an Error or happens to have a
 * 'message' property (which happens when we throw `{code: N, message: S}`), if it's a non-object
 * value call toString on it, etc.
 * @param error - something thrown
 * @returns a message, if we can find one
 */
const extractErrorDetail = (error: any): string => {
  if (error === null || error === undefined) {
    return '';
  }

  if (typeof error !== 'object') {
    return error.toString();
  }

  if (error instanceof Error || error.hasOwnProperty('message')) {
    return error.message;
  }

  return '';
};

/**
 * Download a CSV export of content entry metadata containing the same content entries as a search query.
 *
 * For easier testing this accepts things that normally come from other hooks as parameters:
 * - searchParams => 'useSearchParamsFromLocation'
 * - showToast => 'useAppToast'
 *
 * @param searchParams content search parameters to filter with
 * @param showToast callback for displaying popup message on failure
 * @returns function to download an export for a language, and a boolean indicating if a download is in progress.
 */
export const useSearchResultsExport = (
  searchParams: ContentEntrySearchParams,
  showToast: (toast: AppToastAction) => void
) => async (language: LanguageModel) => {
  const exportParams = {
    languageId: language.id,
    internalBaseUri: `${window.location.origin}/edit/`,
    ..._.omit(searchParams, 'localeIds'),
  };

  const [saveFile, cleanup] = initializeSaveFile();

  try {
    const content = await ContentEntry.searchExportAsync(exportParams);
    const filename = generateExportFilename(language);
    saveFile(content, filename);
  } catch (error) {
    console.error(error);

    const friendly = "Sorry! The export didn't work. Please try again.";
    const detail = extractErrorDetail(error);
    const message = detail ? `${friendly} (${detail})` : friendly;

    showToast({
      level: 'error',
      message: message,
    });
  } finally {
    // release resources
    cleanup();
  }
};

/**
 * Wrapped version of `useSearchResultsExport` for when the function to trigger an export can't be asynchronous.
 * @param searchParams answer search filter parameters from the attribute search component
 * @param showToast function for displaying toasts
 * @returns synchronous function to trigger download and save, and boolean flag for whether an export is in progress
 */
export const useSearchResultsExportSync = (
  searchParams: ContentEntrySearchParams,
  showToast: (toast: AppToastAction) => void
): [(language: LanguageModel) => void, boolean] => {
  const [isExporting, setIsExporting] = useState(false);
  const doExportAsync = useSearchResultsExport(searchParams, showToast);

  const doExportSync = (language: LanguageModel) => {
    if (isExporting) {
      console.warn(
        'search result export triggered while another was in progress'
      );
      return;
    }
    setIsExporting(true);
    doExportAsync(language).finally(() => setIsExporting(false));
  };

  return [doExportSync, isExporting];
};
