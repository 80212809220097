import {
  DisplayName,
  DisplayNameExtended,
  DisplayNameSingle,
  Field,
  isArrayField,
  isDisplayNameExtended,
  isObjectField,
} from './types';

export const parsePath = (key: string) =>
  key.split('.').map((part) => {
    const arrayMatch = part.match(/^([^[]+)\[(\d+)\]$/);

    if (arrayMatch) {
      return {
        property: arrayMatch[1],
        index: parseInt(arrayMatch[2], 10),
      };
    }

    return { property: part };
  });

export const getArrayDisplayName = (field: ContentTypeArrayField) => {
  const { displayName: plural } = field.editor;
  const { displayName: singular } = field.items.editor;

  return { plural, singular };
};

export function getDisplayName(
  field: Field,
  pathItem: { property: string; index?: number }
): DisplayName {
  // Array field
  if (isArrayField(field)) {
    const { plural, singular } = getArrayDisplayName(
      field as ContentTypeArrayField
    );
    const displayName: DisplayNameExtended = {
      key: pathItem.property,
      plural,
      singular,
      // For array fields, we can include a suffix with index.
      withIndex: `${singular} ${pathItem.index! + 1}`,
    };
    return displayName;
  }

  // Object field
  if (isObjectField(field)) {
    const displayName: DisplayNameSingle = {
      key: pathItem.property,
      singular: field.editor.displayName || field.name,
    };
    return displayName;
  }

  // Non-array, non-object field
  return {
    key: pathItem.property,
    singular: field.editor.displayName || field.name,
  };
}

export function getDisplayNames(
  path: Array<{ property: string; index?: number }>,
  fields: Field[]
): DisplayName[] {
  if (path.length === 0) return [];

  const [current, ...rest] = path;
  const field = fields.find(({ name }) => name === current.property);
  if (!field) return [];

  const displayName = getDisplayName(field, current);

  // Object
  if (isObjectField(field)) {
    return [displayName, ...getDisplayNames(rest, field.fields)];
  }

  // Array of objects
  if (isArrayField(field) && isObjectField(field.items)) {
    return [displayName, ...getDisplayNames(rest, field.items.fields)];
  }

  // Everything else
  return [displayName];
}

export const isCommand = (displayName: DisplayName) => {
  return displayName.key === 'command';
};

export const isMedia = (displayName: DisplayName) => {
  return displayName.key === 'media';
};

export const shouldIncludeIndex = (displayName: DisplayName) => {
  const exceptions = ['indicators', 'notes'];

  if (!isDisplayNameExtended(displayName)) {
    return false;
  }

  if (exceptions.includes(displayName.key)) {
    return false;
  }

  return true;
};

// Overrides field display names from the schema for specific cases
export const applyTextRules = (displayName: DisplayName) => {
  if (isCommand(displayName)) return 'Text';
  if (isMedia(displayName)) return 'Image';

  if (isDisplayNameExtended(displayName)) {
    if (shouldIncludeIndex(displayName)) {
      return `${displayName.withIndex}`;
    }
  }

  return displayName.singular;
};

export const getPathText = (fieldDisplayNames: DisplayName[]): string => {
  const current = fieldDisplayNames[0];
  const rest = fieldDisplayNames.slice(1);

  const currentPartText = applyTextRules(current);

  if (rest.length === 0) {
    return currentPartText;
  }

  return `${currentPartText} › ${getPathText(rest)}`;
};
