import { useCallback, useState } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import { recursiveJobRequest } from 'services/ozmo-api/utils/recursive-job-request';
import { SelectedReference } from 'scenes/nuevo-collection/hooks/use-content-tab';

import {
  isStart,
  Status,
  toComplete,
  toCompleteWithFailures,
  toError,
  toFailureEntry,
  toInProgress,
  toStart,
} from './status';

export const useBulkTranslate = (
  categoryContentEntries: CategoryContentEntry[] | SelectedReference[]
) => {
  const api = useOzmoApiService();
  const [status, setStatus] = useState<Status>(toStart());
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const { all: allLanguages } = api.Language.getAll() ?? [];

  const onSubmit = useCallback(async () => {
    if (!isStart(status)) {
      return;
    }

    setStatus(toInProgress());

    try {
      // assemble bulk ops, removing unlocalized versions
      const bulkOps = categoryContentEntries
        .flatMap((cce) =>
          selectedLanguages.map((lang) => {
            return assembleBulkOperation(
              cce.localizedContentEntryId ?? 0,
              'translate',
              undefined,
              lang
            );
          })
        )
        .filter((op) => op.id !== 0);

      // start and poll bulk job
      const { job } = await api.LocalizedContentEntry.bulkAsync(bulkOps);
      const batchResults = await recursiveJobRequest<BulkOperationJobResponse>(
        job
      );

      // build list of failed items
      const failedIds = batchResults.results
        ?.filter((r) => r.status !== 200)
        .map((r) => r.id);

      const failedEntries = categoryContentEntries.filter((cce) =>
        failedIds?.includes(cce.localizedContentEntryId ?? 0)
      );

      if (failedEntries.length > 0) {
        setStatus(toCompleteWithFailures(failedEntries.map(toFailureEntry)));
        return;
      }

      setStatus(toComplete());
    } catch (error) {
      setStatus(toError((error as unknown) as Error));
    }
  }, [
    api.LocalizedContentEntry,
    categoryContentEntries,
    selectedLanguages,
    status,
  ]);

  const onClose = useCallback(() => {
    setStatus(toStart());
    setSelectedLanguages([]);
  }, []);

  const availableTargetLanguages = allLanguages
    ?.filter((lang) => lang.shortCode !== 'en')
    ?.map(({ shortCode, displayName }) => ({
      value: shortCode,
      chipLabel: shortCode,
      label: `${displayName} (${shortCode})`,
    }));

  return {
    status,
    onSubmit,
    onClose,
    availableTargetLanguages,
    selectedLanguages,
    setSelectedLanguages,
  };
};
