import { Model, Factory } from 'miragejs';

import { ServerWithRegistry } from '../server';

type ModelWithTraits = HistoryEventModel;

export const model = Model.extend<HistoryEventModel>(
  // @ts-expect-error
  {}
);

const nextMinute = (i: number) => {
  const date = new Date('2025-03-01T00:00:00');
  date.setMinutes(date.getMinutes() + i);
  return date.toISOString();
};

export const factory = Factory.extend<ModelWithTraits, ServerWithRegistry>({
  createdAt: (i) => nextMinute(i),
  user: () => ({
    id: 1,
    email: 'jack.smith@ozmo.com',
    firstName: 'Jack',
    lastName: 'Smith',
    picture: '/mock/path/to/picture.png',
  }),
  contentEntryId: () => 1,
  action: () => 'add_attribute',
  details: () => ({
    id: 1,
    name: 'attribute name',
    type: 'device_type',
  }),
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get(
    '/authoring/content_entries/:contentEntryId/localized_content_entries/:id/history',
    (schema) => schema.all('historyEvent')
  );
};
