import { SelectedAttributes } from 'components/attribute-selector';

export enum SendToMethod {
  ByReference = 'reference',
  ByDuplicate = 'duplicate',
}

// NOTE using null instead of undefined here is INTENTIONAL. The value gets prop drilled to a MUI
// Autocomplete component, where any initial value except 'undefined' configures the component to
// be 'controlled' (i.e. we manage its value prop ourselves).
export type ModalContentState = {
  destCollectionId: number | null;
  destCategoryIds: number[];
  sendToMethod: SendToMethod | null;
  duplicateSet: boolean;
  selectedSpaceId?: number;
  attributes?: SelectedAttributes;
  sent: boolean;
};

type ValueAction<Type, Value> = {
  type: Type;
  value: Value;
};

export type ModalContentAction =
  | ValueAction<'ChangeDestCollection', number | null>
  | ValueAction<'ChangeDestCategories', number[]>
  | ValueAction<'ChangeSendToMethod', SendToMethod>
  | ValueAction<'ChangeDuplicateSet', boolean>
  | ValueAction<'ChangeSelectedSpaceId', number>
  | ValueAction<'ChangeAttributes', SelectedAttributes>
  | ValueAction<'ChangeSent', boolean>;

export function reducer(
  state: ModalContentState,
  action: ModalContentAction
): ModalContentState {
  switch (action.type) {
    case 'ChangeDestCollection': {
      return {
        ...state,
        destCollectionId: action.value,
        // IMPORTANT - clear any selected categories if the collection changes
        destCategoryIds: [],
      };
    }
    case 'ChangeDestCategories': {
      return {
        ...state,
        destCategoryIds: action.value,
      };
    }
    case 'ChangeSendToMethod': {
      return {
        ...state,
        sendToMethod: action.value,
      };
    }
    case 'ChangeDuplicateSet': {
      return {
        ...state,
        duplicateSet: action.value,
      };
    }
    case 'ChangeSelectedSpaceId': {
      return {
        ...state,
        selectedSpaceId: action.value,
      };
    }
    case 'ChangeAttributes': {
      return {
        ...state,
        attributes: action.value,
      };
    }
    case 'ChangeSent': {
      return {
        ...state,
        sent: action.value,
      };
    }
  }
}

export const InitialState: ModalContentState = {
  destCollectionId: null,
  destCategoryIds: [],
  sendToMethod: null,
  duplicateSet: false,
  selectedSpaceId: undefined,
  attributes: {},
  sent: false,
};
