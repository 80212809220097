import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';

import { HistoryEvent, isAttributeEvent, isContentEvent } from '../types';

import ContentChangeDetails from './content-change-details';
import AttributeChangeDetails from './attribute-change-details';

const EventDetails = ({ historyEvent }: { historyEvent: HistoryEvent }) => {
  const { localizedContentEntry } = useCurrentLocalizedContentEntry();

  if (isContentEvent(historyEvent))
    return <ContentChangeDetails historyEvent={historyEvent} />;

  if (isAttributeEvent(historyEvent))
    return <AttributeChangeDetails historyEvent={historyEvent} />;

  return <>{localizedContentEntry?.languageDisplayName || null}</>;
};

export default EventDetails;
