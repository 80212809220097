// Contents of this file are taken from the slate markdown preview example
// https://github.com/ianstormtaylor/slate/blob/main/site/examples/markdown-preview.tsx

import Prism from 'prismjs';

// eslint-disable-next-line
(Prism.languages.markdown = Prism.languages.extend('markup', {})),
  Prism.languages.insertBefore('markdown', 'prolog', {
    blockquote: { pattern: /^>(?:[\t ]*>)*/m, alias: 'punctuation' },
    code: [
      { pattern: /^(?: {4}|\t).+/m, alias: 'keyword' },
      { pattern: /``.+?``|`[^`\n]+`/, alias: 'keyword' },
    ],
    title: [
      {
        pattern: /\w+.*(?:\r?\n|\r)(?:==+|--+)/,
        alias: 'important',
        inside: { punctuation: /==+$|--+$/ },
      },
      {
        pattern: /(^\s*)#+.+/m,
        lookbehind: !0,
        alias: 'important',
        inside: { punctuation: /^#+|#+$/ },
      },
    ],
    hr: {
      pattern: /(^\s*)([*-])([\t ]*\2){2,}(?=\s*$)/m,
      lookbehind: !0,
      alias: 'punctuation',
    },
    list: {
      pattern: /<ul>([\s\S]*?)<\/ul>/,
      inside: {
        'list-item': {
          pattern: /<li>([\s\S]*?)<\/li>/g,
          inside: {
            rest: Prism.languages.markdown,
          },
        },
      },
    },
    'url-reference': {
      pattern: /!?\[[^\]]+\]:[\t ]+(?:\S+|<(?:\\.|[^>\\])+>)(?:[\t ]+(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\)))?/,
      inside: {
        variable: { pattern: /^(!?\[)[^\]]+/, lookbehind: !0 },
        string: /(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\))$/,
        punctuation: /^[[\]!:]|[<>]/,
      },
      alias: 'url',
    },
    bold: {
      pattern: /(^|[^\\])(\*\*|__)(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
      lookbehind: !0,
      inside: { punctuation: /^\*\*|^__|\*\*$|__$/ },
    },
    italic: {
      pattern: /(^|[^\\])([*_])(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
      lookbehind: !0,
      inside: { punctuation: /^[*_]|[*_]$/ },
    },
    url: {
      pattern: /!?\[[^\]]+\](?:\([^\s)]+(?:[\t ]+"(?:\\.|[^"\\])*")?\)| ?\[[^\]\n]*\])/,
      inside: {
        variable: { pattern: /(!?\[)[^\]]+(?=\]$)/, lookbehind: !0 },
        string: { pattern: /"(?:\\.|[^"\\])*"(?=\)$)/ },
      },
    },
  }),
  // @ts-ignore
  (Prism.languages.markdown.bold.inside.url = Prism.util.clone(
    Prism.languages.markdown.url
  )),
  // @ts-ignore
  (Prism.languages.markdown.italic.inside.url = Prism.util.clone(
    Prism.languages.markdown.url
  )),
  // @ts-ignore
  (Prism.languages.markdown.bold.inside.italic = Prism.util.clone(
    // @ts-ignore
    Prism.languages.markdown.italic
  )),
  // @ts-ignore
  (Prism.languages.markdown.italic.inside.bold = Prism.util.clone(
    // @ts-ignore
    Prism.languages.markdown.bold
  ));

// Ensure bold, italic, and URLs can be nested within list items
// @ts-ignore
const listItemPattern = Prism.languages.markdown.list.inside['list-item'];
listItemPattern.inside.rest.bold = Prism.util.clone(
  // @ts-ignore
  Prism.languages.markdown.bold
);
listItemPattern.inside.rest.italic = Prism.util.clone(
  // @ts-ignore
  Prism.languages.markdown.italic
);
listItemPattern.inside.rest.url = Prism.util.clone(
  Prism.languages.markdown.url
);

// add tutorial directive to the markdown language
const tutorial = {
  pattern: /::tutorial\[[^\]]+\]\{[^}]+\}/,
  inside: {
    keyword: /::tutorial/,
    title: {
      pattern: /\[[^\]]*\]/,
      inside: {
        punctuation: /[[\]]/,
      },
    },
    description: {
      pattern: /description="[^"]*"/,
      inside: {
        punctuation: /["=]/,
      },
    },
    id: {
      pattern: /id="[^"]*"/,
      inside: {
        punctuation: /["=]/,
      },
    },
    punctuation: /[{}]/,
  },
};

// @ts-ignore
Prism.languages.markdown['tutorial'] = tutorial;

// add step header directive to the markdown language
const stepHeader = {
  pattern: /::step\[[^\]]+\]\{[^}]+\}/,
  inside: {
    keyword: /::step/,
    title: {
      pattern: /\[[^\]]*\]/,
      inside: {
        punctuation: /[[\]]/,
      },
    },
    number: {
      pattern: /number="[^"]*"/,
      inside: {
        punctuation: /["=]/,
      },
    },
    punctuation: /[{}]/,
  },
};

// @ts-ignore
Prism.languages.markdown['step-header'] = stepHeader;

// add step header directive for markdown

/* WARNING: CANNOT GET TO WORK YET
 * TUTORIAL IS FINE BUT CHOICE IS NOT WORKING
 * GOING BACK TO PLAIN TEXT FOR THIS POC

// Update choice directive to be a container directive
const choice = {
  // Match full container block from :::choice to :::
  pattern: /^:::choice\s*$[\s\S]*?^:::\s*$/m,
  greedy: true,
  inside: {
    // Style the container delimiters separately if needed
    delimiter: /^:::choice$|^:::$/m,
  },
};

// @ts-ignore
Prism.languages.markdown['choice'] = choice;
*/
