import { FC, useMemo, MouseEventHandler, ReactNode } from 'react';
import { Typography, Button, ButtonProps } from '@mui/material';
import LoadingButton from 'components/loading-button';

import BaseModal, { Props as BaseModalProps } from './base';

export type Props = Omit<BaseModalProps, 'actionChildren'> & {
  message: string | ReactNode;
  closeButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: ButtonProps['color'];
  confirmButtonStartIcon?: React.ReactNode;
  confirmButtonEndIcon?: React.ReactNode;
  disableConfirmButton?: boolean;
  confirmInProgress?: boolean;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
};

export const ConfirmModal: FC<Props> = (props) => {
  const {
    message,
    confirmButtonText = 'Confirm',
    confirmButtonColor = 'primary',
    confirmButtonStartIcon,
    confirmButtonEndIcon,
    disableConfirmButton,
    confirmInProgress,
    confirmInProgressText = confirmButtonText,
    closeButtonText = 'Cancel',
    onConfirm,
    onClose,
    ...baseProps
  } = props;

  const actionButtons = useMemo(() => {
    return (
      <>
        <Button onClick={onClose} color="primary">
          {closeButtonText}
        </Button>
        <LoadingButton
          isLoading={confirmInProgress}
          color={confirmButtonColor}
          onClick={onConfirm}
          disabled={disableConfirmButton}
          variant="contained"
          startIcon={confirmButtonStartIcon}
          endIcon={confirmButtonEndIcon}
        >
          {confirmInProgress ? confirmInProgressText : confirmButtonText}
        </LoadingButton>
      </>
    );
  }, [
    onClose,
    closeButtonText,
    confirmInProgress,
    confirmButtonColor,
    onConfirm,
    disableConfirmButton,
    confirmButtonStartIcon,
    confirmButtonEndIcon,
    confirmInProgressText,
    confirmButtonText,
  ]);

  return (
    <BaseModal actionChildren={actionButtons} onClose={onClose} {...baseProps}>
      <Typography>{message}</Typography>
    </BaseModal>
  );
};
