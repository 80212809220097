/**
 * Defines valid HTTP request method names, per:
 * <https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods>
 *
 * Omits CONNECT (unused), TRACE (unsupported)
 *
 * Definition and validation based on:
 * <https://stackoverflow.com/a/61129291>
 */

const httpMethodNames = [
  'GET',
  'HEAD',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
  'OPTIONS',
] as const;

export type HttpMethod = typeof httpMethodNames[number];

export const isHttpMethod = (value: unknown): value is HttpMethod =>
  typeof value === 'string' && httpMethodNames.includes(value as HttpMethod);
