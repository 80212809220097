import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

import {
  AttributeEvent,
  AttributesEvent,
  HistoryEvent,
  isAttributesEvent,
  isIndicatorChangedEvent,
} from './types';

export const flattenAttributesChanges = (historyEvent: AttributesEvent) =>
  historyEvent.details.map(
    (detail) =>
      ({
        ...historyEvent,
        action: detail.action === '+' ? 'add_attribute' : 'delete_attribute',
        details: {
          id: detail.id,
          name: detail.name,
          type: detail.type,
        },
      } as AttributeEvent)
  );

// Gets the key of the indicator from the full path key
export const getIndicatorKey = (fullPathKey: string) => {
  // If it belongs to an array of indicators:
  if (fullPathKey.includes('.indicators['))
    return fullPathKey.replace(/(indicators\[\d+\])(\..*)?$/, '$1');
  // If it's not an array of indicators:
  return fullPathKey.replace(/(indicator)(\..*)?$/, '$1');
};

export const squashIndicatorUpdate = (
  acc: HistoryEvent[],
  currentEvent: HistoryEvent
) => {
  const previousEvent = acc[acc.length - 1];
  // If there are two consecutive indicator change events on the same indicator, we want to merge these into a single event
  if (
    // Check for two consecutive indicator change events
    acc.length > 0 &&
    isIndicatorChangedEvent(currentEvent) &&
    isIndicatorChangedEvent(previousEvent) &&
    // Check if they are both on the same indicator
    getIndicatorKey(currentEvent.details.key) ===
      getIndicatorKey(previousEvent.details.key)
  ) {
    return [
      ...acc.slice(0, acc.length - 1),
      {
        ...currentEvent,
        details: {
          key: currentEvent.details.key,
          before: previousEvent.details.before,
          after: currentEvent.details.after,
        },
      },
    ];
  }

  return [...acc, currentEvent];
};

export const simplifyHistory = (
  historyEvents: HistoryEvent[]
): HistoryEvent[] => {
  const withAttributeChangesFlattened = historyEvents.flatMap(
    (historyEvent): HistoryEvent => {
      // Where an 'attributes_change' event contains multiple additions/deletions, we want to flatten these into individual events
      if (isAttributesEvent(historyEvent)) {
        // @ts-ignore
        return flattenAttributesChanges(historyEvent);
      }

      return historyEvent;
    }
  );

  const withIndicatorUpdatesSquashed = withAttributeChangesFlattened.reduce(
    squashIndicatorUpdate,
    [] as HistoryEvent[]
  );

  return withIndicatorUpdatesSquashed;
};

export const useAnswerHistory = () => {
  const {
    contentEntry,
    localizedContentEntry,
  } = useCurrentLocalizedContentEntry();

  const api = useOzmoApiService();

  const { all, isLoading } = api.HistoryEvent.getAll(
    {
      contentEntryId: contentEntry?.id,
      id: localizedContentEntry?.id,
    },
    {
      refetchOnMount: 'always',
    }
  );

  const history = simplifyHistory(all as HistoryEvent[]);

  return { history, isLoading };
};
