import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { Translate } from '@mui/icons-material';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';
import { SelectedReference } from 'scenes/nuevo-collection/hooks/use-content-tab';
import TranslateBulkModal, {
  useBulkTranslate,
  isStart,
  isComplete,
  isInProgress,
} from 'scenes/translations/translate-bulk';
import { useActionModal } from 'components/modals';

import ActionButton from '../action-button';

type Props = {
  selectedReferences: SelectedReference[];
  language?: string;
};

const TranslateSelected: FC<Props> = ({ selectedReferences, language }) => {
  const { language: collectionLanguage } = useCurrentCollection();

  if (!language) {
    language = collectionLanguage;
  }

  const {
    status,
    onSubmit,
    onClose,
    availableTargetLanguages,
    selectedLanguages,
    setSelectedLanguages,
  } = useBulkTranslate(selectedReferences);

  const { modal, openModal } = useActionModal({
    onRefuse: onClose,
    onConfirm: isComplete(status) ? onClose : onSubmit,
    closeOnConfirm: false,
    modalContent: (
      <TranslateBulkModal
        status={status}
        answerCount={selectedReferences.length}
        targetLanguages={availableTargetLanguages}
        onChangeTargetLanguages={setSelectedLanguages}
      />
    ),
    modalProps: {
      title: isStart(status)
        ? 'Translate multiple answers in this collection'
        : '',
      disableConfirmButton:
        selectedLanguages.length === 0 ||
        !(isStart(status) || isComplete(status)),
      confirmInProgress: isInProgress(status),
      confirmButtonText: isComplete(status) ? 'Close' : 'Translate',
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  return (
    <>
      <Tooltip
        arrow
        title={
          language === 'en'
            ? 'Translate collection'
            : 'Only available in English'
        }
      >
        <div>
          <ActionButton
            disabled={language !== 'en'}
            startIcon={<Translate />}
            onClick={openModal}
          >
            {'Translate'}
          </ActionButton>
        </div>
      </Tooltip>
      {modal}
    </>
  );
};

export default TranslateSelected;
