import useOzmoApiService from 'contexts/ozmo-api-service-context';
import {
  UrlParamOptions,
  UseQueryOptionsWithPrefetch,
} from 'services/ozmo-api/use-query-cache';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';

import { AttributeEvent } from '../types';

export const useAttributeLabel = (historyEvent: AttributeEvent) => {
  const cacheOptions: UseQueryOptionsWithPrefetch = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  };
  const queryOptions: UrlParamOptions = { perPage: 1000 };

  const api = useOzmoApiService();

  switch (historyEvent.details.type) {
    case 'device': {
      const { all: devices, isLoading } = api.Device.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!devices) return { isLoading, label: null };

      const device = devices.find(({ id }) => id === historyEvent.details.id);

      if (!device) return { isLoading, label: null };

      const { trackingName, manufacturer } = device;
      return {
        isLoading,
        label: `${manufacturer} ${formatAttributeName(trackingName)}`,
      };
    }

    case 'device_type': {
      const { all: deviceTypes, isLoading } = api.DeviceType.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!deviceTypes) return { isLoading, label: null };

      const deviceType = deviceTypes.find(
        ({ id }) => id === historyEvent.details.id
      );

      if (!deviceType) return { isLoading, label: null };

      return { isLoading, label: formatAttributeName(deviceType.name) };
    }

    case 'manufacturer': {
      const { all: manufacturers, isLoading } = api.Manufacturer.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!manufacturers) return { isLoading, label: null };

      const manufacturer = manufacturers.find(
        ({ id }) => id === historyEvent.details.id
      );

      if (!manufacturer) return { isLoading, label: null };

      return { isLoading, label: manufacturer.name };
    }

    case 'operating_system': {
      const {
        all: operatingSystemVersions,
        isLoading,
      } = api.OperatingSystemVersion.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!operatingSystemVersions) return { isLoading, label: null };

      const operatingSystemVersion = operatingSystemVersions.find(
        ({ operatingSystemId }) => operatingSystemId === historyEvent.details.id
      );

      if (!operatingSystemVersion) return { isLoading, label: null };

      return {
        isLoading,
        label: `${operatingSystemVersion.operatingSystem} ${operatingSystemVersion.name}`,
      };
    }

    case 'operating_system_release': {
      const {
        isLoading,
        all: operatingSystemVersions,
      } = api.OperatingSystemVersion.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!operatingSystemVersions) return { isLoading, label: null };

      const operatingSystemVersion = operatingSystemVersions.find(
        ({ operatingSystemReleaseId }) =>
          operatingSystemReleaseId === historyEvent.details.id
      );

      if (!operatingSystemVersion) return { isLoading, label: null };

      return {
        isLoading,
        label: `${operatingSystemVersion.operatingSystem} ${operatingSystemVersion.name}`,
      };
    }

    case 'operating_system_version': {
      const {
        isLoading,
        all: operatingSystemVersions,
      } = api.OperatingSystemVersion.getAll(
        undefined,
        cacheOptions,
        queryOptions
      );

      if (!operatingSystemVersions) return { isLoading, label: null };

      const operatingSystemVersion = operatingSystemVersions.find(
        ({ id }) => id === historyEvent.details.id
      );

      if (!operatingSystemVersion) return { isLoading, label: null };

      return {
        isLoading,
        label: `${operatingSystemVersion.operatingSystem} ${operatingSystemVersion.name}`,
      };
    }

    default:
      return {
        isLoading: false,
        label: null,
      };
  }
};
