import { FCWithChildren } from 'types/fc-with-children';
import { styled } from '@mui/material';

// Styled component because the various border-x-radius
// options are not available in Box or sx
const StyledToolbar = styled('div')`
  background-color: var(--color-neutral-one);
  border: 1px solid var(--color-neutral-four);

  display: flex;
  flex-direction: row;
`;

const Toolbar: FCWithChildren = ({ children }) => (
  <StyledToolbar>{children}</StyledToolbar>
);

export default Toolbar;
