import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
  text: string;
};

/**
 * By default markdown only considers a line break to be a "new line"
 * if it is followed by two spaces.  This is not how the editor, aomori, or
 * common sense treat it.  This converts each line into a separate markdown "unit".
 * @param text The string markdown text to display as HTML
 * @returns React nodes that have been safely converted to HTML with sensible newlines
 */
const MarkdownTextWithNewlines: FunctionComponent<Props> = ({ text }) => {
  return (
    <>
      {text.split('\n').map((line) => (
        <ReactMarkdown key={line} linkTarget="_blank">
          {line}
        </ReactMarkdown>
      ))}
    </>
  );
};

export default MarkdownTextWithNewlines;
