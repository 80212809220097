import { FCWithChildren } from 'types/fc-with-children';
import { Button, IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';

type Props = {
  selectedCount: number;
  allSelected?: boolean;
  onSelectAll?: VoidFunction;
  onClose?: VoidFunction;
  className?: string;
};

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: var(--color-neutral-eight);
  position: absolute;
  align-items: center;

  button {
    transition: color 0.2s ease-in-out;
  }
`;

const SelectAllButton = styled(Button)`
  font-weight: bold;
  color: var(--color-neutral-one);
  text-decoration: underline !important;
  margin-right: 0.5rem;

  :hover {
    color: var(--color-primary-dark-contrast);
  }
`;

const StyledIconButton = styled(IconButton)`
  color: var(--color-neutral-one);

  :hover {
    color: var(--color-primary-dark-contrast);
  }
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1.75rem;
`;

const BulkActionBar: FCWithChildren<Props> = ({
  selectedCount,
  allSelected,
  onSelectAll,
  onClose,
  className,
  children,
}) => {
  if (selectedCount <= 0) return null;
  const selectAllText = allSelected ? 'Deselect all' : 'Select all';
  return (
    <Wrapper data-testid="bulk-action-bar" className={className}>
      {/* if onSelectAll is defined, provide select all button */}
      {onSelectAll && (
        <SelectAllButton variant="text" onClick={onSelectAll}>
          {selectAllText}
        </SelectAllButton>
      )}
      <Typography
        color="var(--color-neutral-one)"
        flexGrow={1}
      >{`(${selectedCount} selected)`}</Typography>
      <ActionsWrapper>
        {
          <>
            {children}{' '}
            {onClose && (
              <StyledIconButton onClick={onClose}>
                <Close />
              </StyledIconButton>
            )}
          </>
        }
      </ActionsWrapper>
    </Wrapper>
  );
};

export default BulkActionBar;
