import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { SendToMethod } from './state';

export type Props = {
  value: SendToMethod | null;
  pluralize: boolean;
  onChange: (newValue: SendToMethod) => void;
};

// Used for 'aria-labelledby' on RadioGroup
const radioGroupId = 'send-to-collection-dialog:send-to-method-label';

export const SendToMethodInput = ({ value, pluralize, onChange }: Props) => {
  const subjectText = pluralize ? 'these answers' : 'this answer';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onChange(value as SendToMethod);
  };

  return (
    <FormControl>
      <FormLabel
        id={radioGroupId}
        sx={{
          fontWeight: 500,
          lineHeight: 1.5,
          // override FormLabel changing the text color when its input is focused
          '&.Mui-focused': {
            color: 'inherit',
          },
        }}
      >
        I want to...
      </FormLabel>
      <RadioGroup
        aria-labelledby={radioGroupId}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value={SendToMethod.ByReference}
          control={<Radio />}
          label={`Reference ${subjectText} in the collection`}
        />
        <FormControlLabel
          value={SendToMethod.ByDuplicate}
          control={<Radio />}
          label={`Send a duplicate of ${subjectText} to the collection`}
        />
      </RadioGroup>
    </FormControl>
  );
};
