import { FC } from 'react';
import AutocompleteMultiselect from 'components/autocomplete-multiselect';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

type Props = {
  collectionId: number | null;
  selectedCategories: number[];
  onSelectionChange: (selected: number[]) => void;
};

type MultiselectItem = { id: number; name: string };

const categoryToOption = ({ id, name }: CategoryModel): MultiselectItem => ({
  id,
  name,
});

export const CategorySelectInput: FC<Props> = ({
  collectionId,
  selectedCategories,
  onSelectionChange,
}) => {
  const api = useOzmoApiService();

  const {
    data: collection,
    isLoading: collectionIsLoading,
  } = api.Collection.get({
    id: collectionId ?? undefined,
  });

  const availableOptions = collection?.categories?.map(categoryToOption) ?? [];

  const categoryNames = new Map(
    availableOptions.map((opt) => [opt.id, opt.name])
  );

  const selectedOptions = selectedCategories.map((id) => ({
    id,
    name: categoryNames.get(id) ?? '?',
  }));

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newSelectedValues: MultiselectItem[]
  ) => {
    const selectedIds = newSelectedValues.map(({ id }) => id);
    onSelectionChange(selectedIds);
  };

  const disabled =
    collectionId === null || collectionIsLoading || availableOptions.length < 1;

  return (
    <AutocompleteMultiselect
      isLoading={disabled}
      label="Category"
      options={availableOptions}
      value={selectedOptions}
      onChange={handleChange}
    />
  );
};
