import OzmoApiBase from '../ozmo-api-base';
import { UseQueryOptionsWithPrefetch } from '../use-query-cache';

interface ResourcePathVariables {
  contentEntryId?: number;
  id?: number;
}

class HistoryEvent extends OzmoApiBase<
  // @ts-ignore
  HistoryEventModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath =
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id/history';
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 60000, // 1 minute
  };

  protected static readonly = true;
}

export default HistoryEvent;
