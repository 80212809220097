import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
import api, { ozmoApiRequest } from 'services/ozmo-api';

import {
  createData,
  onMutationError,
  onUpdateSuccess,
  updateCacheOptimistically,
  UseQueryOptionsWithPrefetch,
} from '../use-query-cache';

interface ResourcePathVariables {
  id?: number;
  contentEntryId?: number;
}
class LocalizedContentEntry extends OzmoApiBase<
  LocalizedContentEntryModel,
  LocalizedContentEntryUpdateModel,
  LocalizedContentEntryCreateModel,
  ResourcePathVariables
>() {
  protected static resourcePath =
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 60000, // 1 minute
  };

  static translateAsync = async ({
    id,
    contentEntryId,
    targetLanguageShortCode,
  }: {
    id: number;
    contentEntryId: number;
    targetLanguageShortCode: string;
  }): Promise<LocalizedContentEntryModel> => {
    const resource = `authoring/content_entries/${contentEntryId}/localized_content_entries/${id}/translate?target=${targetLanguageShortCode}`;
    const { data: newLce } = await ozmoApiRequest(resource, undefined, 'POST');
    return newLce;
  };

  static restoreAsync = async ({
    id,
    contentEntryId,
  }: {
    id: number;
    contentEntryId: number;
  }): Promise<LocalizedContentEntryModel> => {
    const resource = `authoring/content_entries/${contentEntryId}/localized_content_entries/${id}/restore`;
    const { data: lce } = await ozmoApiRequest(
      resource,
      undefined,
      'POST',
      true,
      false
    );
    return lce;
  };

  static updateStatusAsync = async (
    id: number,
    contentEntryId: number,
    status: string
  ): Promise<LocalizedContentEntryModel> => {
    const resource = `authoring/content_entries/${contentEntryId}/localized_content_entries`;
    const queryKey = LocalizedContentEntry.getQueryKey({
      contentEntryId,
      id,
    });

    const previousVersion = api.queryClient.getQueryData(queryKey);
    try {
      // Optimistically update the cache
      updateCacheOptimistically(api.queryClient, queryKey, {
        status,
      });
      const result: any = await ozmoApiRequest(
        `${resource}/${id}/update_status`,
        { status },
        'PATCH'
      );
      if ('code' in result) {
        onMutationError(
          api.queryClient,
          queryKey,
          result,
          { status },
          previousVersion
        );
        throw result;
      }
      onUpdateSuccess(
        api.queryClient,
        queryKey,
        result,
        { status },
        previousVersion
      );
      return result;
    } catch (error) {
      onMutationError(
        api.queryClient,
        queryKey,
        error,
        { status },
        previousVersion
      );
      throw error;
    }
  };

  public static bulkAsync(
    operations: BulkOperation[]
  ): Promise<{ job: string }> {
    const body = operations.map((op) => JSON.stringify(op)).join('\n');
    return createData(
      'authoring/batch/localized_content_entries',
      body,
      undefined,
      undefined,
      false,
      { 'Content-Type': 'application/x-ndjson' }
    );
  }
}

export default LocalizedContentEntry;
