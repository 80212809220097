import CopyButton from 'components/copy-button';
import { usePermissions } from 'components/permission-required';
import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import DetailsPage from 'scenes/universal-content-editor/components/details-page';
import { ContentEntryHeader } from 'scenes/sticky-header';
import TabbedContent from 'scenes/tabbed-content';
import AttributeEditor from 'components/attribute-editor';
import { isLegacyContent } from 'services/utils/is-legacy-content';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import TranslateContentEntry from 'components/translate-content-entry/translate-content-entry';
import AnswerHistory from 'components/answer-history';

import DeleteRestoreButton from './header-buttons/delete-restore-button';
import PreviewButton from './header-buttons/preview-button';
import PublishButton from './header-buttons/publish-button';
import { useCurrentLanguage } from './hooks/use-current-language';
import UniversalContentEditor from './universal-content-editor';
import { generateEditorPath } from './utils';

const Editor: FunctionComponent = () => {
  const navigate = useNavigate();
  const languageShortCode = useCurrentLanguage();
  const { contentEntryId } = useParams();
  const { checkPermission } = usePermissions();
  const hasAnswerEditorPermission = checkPermission('edit_answer', 'Studio');

  const api = useOzmoApiService();
  const contentEntry = api.ContentEntry.get({ id: Number(contentEntryId) });
  const { contentType } = contentEntry.data ?? {};

  const isLegacy = contentEntry.isLoading || isLegacyContent(contentType);
  const disableEdit = !hasAnswerEditorPermission || isLegacy;

  return (
    <>
      <TabbedContent
        tabLabels={['Content', 'Attributes', 'Details', 'History']}
        header={
          <ContentEntryHeader
            onSelectLanguage={(language: string) =>
              navigate(generateEditorPath(Number(contentEntryId), language))
            }
            contentEntryId={Number(contentEntryId)}
            language={languageShortCode}
            titleField="title"
            actions={[
              <TranslateContentEntry
                key="translate"
                contentEntryId={Number(contentEntryId)}
              />,
              <DeleteRestoreButton
                asMenuItem
                key="delete-restore"
                contentEntryId={Number(contentEntryId)}
              />,
            ]}
            actionButtons={[
              <CopyButton key="copy" contentEntryId={Number(contentEntryId)} />,
              <PreviewButton
                key="preview"
                contentEntryId={Number(contentEntryId)}
                languageShortCode={languageShortCode}
              />,
              <PublishButton
                key="publish"
                contentEntryId={Number(contentEntryId)}
              />,
            ]}
          />
        }
      >
        <UniversalContentEditor />
        <Box height="calc(100vh - 164px)">
          <AttributeEditor contentEntryId={Number(contentEntryId)} />
        </Box>
        <Box marginTop={3}>
          <DetailsPage
            contentEntryId={Number(contentEntryId)}
            disableEdit={disableEdit}
          />
        </Box>
        <AnswerHistory />
      </TabbedContent>
    </>
  );
};

export default Editor;
