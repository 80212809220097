import { FC, useCallback, ChangeEvent } from 'react';
import { ReactEditor, useSlateStatic, RenderElementProps } from 'slate-react';
import { Transforms, CustomTypes } from 'slate';
import { Stack, TextField } from '@mui/material';

interface TutorialEmbedProps extends RenderElementProps {
  element: CustomTypes['Element'] & {
    type: 'tutorial-embed';
    title: string;
    description: string;
    id: string;
  };
}

const TutorialEmbed: FC<TutorialEmbedProps> = ({ attributes, element }) => {
  const editor = useSlateStatic() as ReactEditor;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        { [e.target.name]: e.target.value },
        { at: path }
      );
    },
    [editor, element]
  );

  return (
    <div {...attributes} style={{ flex: 1 }}>
      <Stack
        border={1}
        borderColor="var(--color-primary-base)"
        borderRadius={2}
        paddingY={1}
        paddingX={2}
        contentEditable={false}
      >
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.25rem',
              fontWeight: '500',
              color: 'var(--color-primary-dark)',
            },
          }}
          name="title"
          value={element.title}
          onChange={handleChange}
          placeholder="Title"
        />
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            sx: {
              color: 'var(--color-primary-dark)',
            },
          }}
          name="id"
          value={element.id}
          onChange={handleChange}
          placeholder="id (either content entry id [123456] or topic slug [topic-slug])"
        />
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            sx: {
              color: 'var(--color-primary-dark)',
            },
          }}
          name="description"
          value={element.description}
          onChange={handleChange}
          placeholder="Description (optional)"
        />
      </Stack>
    </div>
  );
};

export default TutorialEmbed;
