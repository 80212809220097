import { FC } from 'react';
import { SelectedReference } from 'scenes/nuevo-collection/hooks/use-content-tab';
import SendIcon from '@mui/icons-material/Send';
import { useSendToCollectionModal } from 'components/send-to-collection';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';

import ActionButton from './action-button';

type Props = {
  selectedReferences: SelectedReference[];
};

const SendToCollection: FC<Props> = ({ selectedReferences }) => {
  const { collectionId } = useCurrentCollection();
  const contentEntryIds = selectedReferences.map((ref) => ref.id);

  const [sendToModal, openSendToModal] = useSendToCollectionModal(
    contentEntryIds,
    collectionId
  );

  return (
    <>
      <ActionButton startIcon={<SendIcon />} onClick={openSendToModal}>
        {'Send to...'}
      </ActionButton>
      {sendToModal}
    </>
  );
};

export default SendToCollection;
