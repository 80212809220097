import { SelectedReference } from 'scenes/nuevo-collection/hooks/use-content-tab';

// tagged status values
export type FailureEntry = { id: number; title: string; slug: string };
export type Start = { type: 'start' };
export type InProgress = { type: 'in-progress' };
export type Complete = { type: 'complete' };
export type CompleteWithFailures = {
  type: 'complete-with-failures';
  failures: FailureEntry[];
};
export type AsError = { type: 'error'; error: Error };

export type Status =
  | Start
  | InProgress
  | Complete
  | CompleteWithFailures
  | AsError;

export const isStart = (status: Status): status is Start =>
  status.type === 'start';

export const isInProgress = (status: Status): status is InProgress =>
  status.type === 'in-progress';

export const isComplete = (status: Status): status is Complete =>
  status.type === 'complete';

export const isCompleteWithFailures = (
  status: Status
): status is CompleteWithFailures => status.type === 'complete-with-failures';

export const isError = (status: Status): status is AsError =>
  status.type === 'error';

export const toStart = (): Start => ({ type: 'start' });
export const toInProgress = (): InProgress => ({ type: 'in-progress' });
export const toComplete = (): Complete => ({ type: 'complete' });
export const toCompleteWithFailures = (
  failures: FailureEntry[]
): CompleteWithFailures => ({
  type: 'complete-with-failures',
  failures,
});
export const toError = (error: Error): AsError => ({
  type: 'error',
  error,
});

export const toFailureEntry = (
  lce: CategoryContentEntry | SelectedReference
): FailureEntry => ({
  id: lce.id,
  title: lce.title ?? 'n/a',
  slug: getSlug(lce),
});

const getSlug = (lce: CategoryContentEntry | SelectedReference): string => {
  if ('topicSlug' in lce) {
    return lce.topicSlug ?? 'n/a';
  }
  return 'n/a';
};
