import { Alert, Box } from '@mui/material';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { SelectedAttributes } from 'components/attribute-selector';
import { SpaceSelect } from 'components/duplicate-content-entry-dialog/space-select';

import { SteptextOnlyCheckbox } from '../duplicate-content-entry-dialog/steptext-only-checkbox';
import { StyledAddAttributeSelector } from '../duplicate-content-entry-dialog/dialog-content';

import { CategorySelectInput } from './category-select-input';
import { CollectionSearchInput } from './collection-search-input';
import Instructions from './instructions';
import { ModalContentAction, ModalContentState, SendToMethod } from './state';
import { SendToMethodInput } from './send-to-method-input';

const ModalContentBox = styled(Box)`
  min-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  gap: 2rem; /* approx 32px */
`;

const StyledSpaceSelect = styled(SpaceSelect)`
  width: 100%;
  &.MuiFormControl {
    margin-top: 0;
    width: 100%;
  }
`;

type Props = {
  contentEntryIds: number[];
  sourceCollectionId: number;
  state: ModalContentState;
  initialAttributes: SelectedAttributes;
  selectedSpaceId: number;
  dispatch: React.Dispatch<ModalContentAction>;
  steptextOnlyCheckboxRef: React.RefObject<HTMLInputElement>;
};

export const ModalContent: FC<Props> = ({
  sourceCollectionId,
  contentEntryIds,
  state,
  initialAttributes,
  selectedSpaceId,
  dispatch,
  steptextOnlyCheckboxRef,
}) => {
  const entriesPlural = contentEntryIds.length > 1;
  const handleAttributesChange = useCallback(
    (selectedAttributes: SelectedAttributes) => {
      if (contentEntryIds.length > 1) {
        return;
      }
      dispatch({
        type: 'ChangeAttributes',
        value: selectedAttributes,
      });
    },
    [contentEntryIds.length, dispatch]
  );
  return (
    <ModalContentBox>
      <Instructions
        contentEntryIds={contentEntryIds}
        duplicate={state.sendToMethod === SendToMethod.ByDuplicate}
      />
      <Box sx={{ width: '100%' }}>
        <CollectionSearchInput
          sx={{ marginBottom: '1em' }}
          originCollectionId={sourceCollectionId}
          selectedCollectionId={state.destCollectionId}
          onSelectionChange={(newValue) =>
            dispatch({ type: 'ChangeDestCollection', value: newValue })
          }
        />
        <CategorySelectInput
          collectionId={state.destCollectionId}
          selectedCategories={state.destCategoryIds}
          onSelectionChange={(newValue) =>
            dispatch({ type: 'ChangeDestCategories', value: newValue })
          }
        />
        {state.duplicateSet && (
          <StyledSpaceSelect
            spaceId={selectedSpaceId ?? 0}
            isSendByDuplicate
            onChange={(spaceId: string | number) =>
              dispatch({
                type: 'ChangeSelectedSpaceId',
                value: Number(spaceId),
              })
            }
          />
        )}
      </Box>
      {!state.duplicateSet ? (
        <SendToMethodInput
          value={state.sendToMethod}
          pluralize={entriesPlural}
          onChange={(newValue) =>
            dispatch({ type: 'ChangeSendToMethod', value: newValue })
          }
        />
      ) : (
        <div>
          <SteptextOnlyCheckbox
            inputRef={steptextOnlyCheckboxRef ?? { current: null }}
          />
          {contentEntryIds.length > 1 ? (
            <Alert severity="warning">
              All attributes of selected answers will be removed and need to be
              re-attributed.
            </Alert>
          ) : (
            <StyledAddAttributeSelector
              startingAttributes={initialAttributes}
              prohibitedAttributes={[
                'ContentTitle',
                'Space',
                'ContentType',
                'Language',
                'Topic',
              ]}
              onSelectedAttrChange={handleAttributesChange}
            />
          )}
        </div>
      )}
    </ModalContentBox>
  );
};
