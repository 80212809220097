import { FunctionComponent } from 'react';
import { ContentEntryHeader } from 'scenes/sticky-header';
import TabbedContent from 'scenes/tabbed-content';
import AttributeEditor from 'components/attribute-editor';
import LoadingIndicator from 'components/loading-indicator';
import CopyButton from 'components/copy-button';

import ContentTab from './content-tab';
import { useCollection, useShowPublishingMoved } from './hooks';
type Props = {};

const Collection: FunctionComponent<Props> = () => {
  const {
    contentEntryId,
    error,
    language,
    localizedContentEntry,
    isLoading,
    handleSelectLanguage,
  } = useCollection();

  // Show a message about publishing moving
  useShowPublishingMoved();

  if (isLoading) return <LoadingIndicator message="Loading collection..." />;

  if (error) return <>{JSON.stringify(error)}</>;

  return (
    <>
      <TabbedContent
        header={
          <ContentEntryHeader
            contentEntryId={contentEntryId}
            language={language!}
            onSelectLanguage={handleSelectLanguage}
            actionButtons={[
              <CopyButton
                key="copy-button"
                contentEntryId={contentEntryId}
                contentType="collection"
                language={language}
              />,
            ]}
          />
        }
        tabLabels={['Content', 'Details']}
      >
        <ContentTab
          contentEntryId={contentEntryId}
          localizedContentEntryId={localizedContentEntry!.id}
        />
        <AttributeEditor contentEntryId={contentEntryId} />
      </TabbedContent>
    </>
  );
};

export default Collection;
