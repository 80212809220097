import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MultiSelect from 'components/multi-select';

import InProgress from '../statuses/in-progress';
import Error from '../statuses/error';
import Complete from '../statuses/complete';
import CompleteWithFailures from '../statuses/complete-with-failures';

import {
  isComplete,
  isCompleteWithFailures,
  isError,
  isInProgress,
  Status,
} from './status';

type Props = {
  status: Status;
  answerCount: number;
  targetLanguages: { value: string; chipLabel: string; label: string }[];
  onChangeTargetLanguages: (languageIds: string[]) => void;
};

const TranslateBulkModal: FC<Props> = ({
  status,
  answerCount,
  targetLanguages,
  onChangeTargetLanguages,
}) => {
  if (isInProgress(status)) {
    return <InProgress />;
  }

  if (isError(status)) {
    return <Error />;
  }

  if (isComplete(status)) {
    return <Complete />;
  }

  if (isCompleteWithFailures(status)) {
    return <CompleteWithFailures failures={status.failures} />;
  }

  return (
    <Stack spacing={2}>
      <Typography>
        {'You are about to translate '}
        <strong>{`${answerCount} answers`}</strong>
        {' from '}
        <strong>{'English (en)'}</strong>
        {' to your selected target languages.'}
      </Typography>
      <Box>
        <MultiSelect
          label="Target languages"
          options={targetLanguages}
          onSelectionChanged={onChangeTargetLanguages}
        />
      </Box>
    </Stack>
  );
};

export default TranslateBulkModal;
