import { FC } from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

type Props = {
  spaceId: number;
  isSendByDuplicate?: boolean;
  onChange: (spaceId: number | string) => void;
};

const StyledFormControl = styled(FormControl)`
  margin-top: 3rem;
  margin-bottom: 1rem;

  &.send-by-duplicate {
    margin: 1.5rem 0 0 0;
    width: 100%;
  }
`;

export const SpaceSelect: FC<Props> = ({
  spaceId,
  onChange,
  isSendByDuplicate = false,
}) => {
  const api = useOzmoApiService();
  const { all: spaces, isLoading: spacesIsLoading } = api.Space.getAll();
  const labelId = 'duplicate-content-entry-space-label';
  return (
    <StyledFormControl className={isSendByDuplicate ? 'send-by-duplicate' : ''}>
      {spacesIsLoading ? (
        <Skeleton data-testid="duplicate-content-entry-space-skeleton" />
      ) : (
        <>
          <InputLabel id={labelId}>{'Space'}</InputLabel>
          <Select
            labelId={labelId}
            label={'Space'}
            id="duplicate-content-entry-space-select"
            value={spaceId}
            onChange={(e) => onChange(e.target.value)}
          >
            {spaces?.map((space) => (
              <MenuItem key={space.id} value={space.id}>
                {space.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </StyledFormControl>
  );
};
