import { isDefined } from 'services/utils/type-guards/generic';
import keysToCamel from 'services/utils/convert-object-keys-snake-to-camel';

export class StaleWriteError extends Error {
  public queryKey;
  constructor(message: string, queryKey: any[]) {
    super(message);
    this.name = 'StaleWriteError';
    this.queryKey = queryKey;
  }
}

export class UnsuccessfulResponseError extends Error {
  response: Response;
  text: string;
  json?: any;

  constructor(response: Response, text: string, json?: any) {
    // create a string message for super
    let message: string;

    if (isDefined(json) && json.message) {
      message = json.message;
    } else if (text) {
      message = text;
    } else {
      message = response.statusText;
    }

    super(message);

    this.response = response;
    this.text = text;
    this.json = json;
  }

  public get isCode401(): boolean {
    return isDefined(this.json) && this.json.code === 401;
  }

  static async fromResponse(response: Response) {
    // you can only read the response body stream once, so if the response body
    // isn't in JSON format we get a parse error and a body we can't read in any
    // other format. So read it as text first, and if even that fails let it crash
    // because what the heck?
    const text = await response.text();

    let json: any = undefined;

    try {
      json = JSON.parse(text);
    } catch (error) {
      if (error instanceof SyntaxError) {
        // not json? that's fine
        return new UnsuccessfulResponseError(response, text);
      } else {
        // something else? not ok
        throw error;
      }
    }

    const recasedJson = keysToCamel(json);
    return new UnsuccessfulResponseError(response, text, recasedJson);
  }
}
