import {
  BaseEditor,
  Descendant,
  Editor,
  Node,
  Text,
  Transforms,
  Element,
} from 'slate';
import { tokenize, languages, TokenStream, Token } from 'prismjs';
import {
  FormattedText,
  LinkElement,
  BlockElement,
  BulletedListElement,
  TutorialEmbedElement,
  TutorialChoiceElement,
  StepHeaderElement,
} from 'types/slate';
import { isNotNull } from 'services/utils/type-guards/generic';

import {
  isLinkElement,
  isPrismToken,
  isListemItemElement,
  MarkOptions,
  BlockOptions,
  isBlockElement,
  ListOptions,
  isTutorialEmbedElement,
  isChoiceGroupElement,
  isStepHeaderElement,
} from './types';
import './prism-markdown';

export const HOTKEYS: { [key: string]: MarkOptions | BlockOptions } = {
  b: 'bold',
  i: 'italic',
  l: 'bulleted-list',
};

export const LISTTYPES: ListOptions[] = ['bulleted-list'];

export const isUrl = (text: string) => new RegExp(/https?:\/\//).test(text);

export const isMarkActive = (editor: BaseEditor, format: MarkOptions) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor: BaseEditor, format: BlockOptions) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && isBlockElement(n) && n.type === format,
    })
  );

  return !!match;
};

export const toggleMark = (editor: BaseEditor, format: MarkOptions) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// Note: The toggleBlock, and other related block utils, are only setup to handle lists atm.
// Some different block types may require additional handling. (Such as text alignment).
// See this example for how to potentially handle other block types:
// https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
export const toggleBlock = (editor: BaseEditor, format: BlockOptions) => {
  const isActive = isBlockActive(editor, format);
  const isList = LISTTYPES.includes(format as ListOptions);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && isBlockElement(n),
    split: true,
  });
  const newProperties: Partial<Element> = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive) {
    const block = { type: format, children: [] } as BlockElement;
    Transforms.wrapNodes(editor, block);
  }
};

// Convert a slate text node into corresponding markdown
const formatTextNode = (node: Text) => {
  let formatted = node.text;
  if (node.bold) {
    formatted = `**${formatted}**`;
  }
  if (node.italic) {
    formatted = `_${formatted}_`;
  }

  return formatted;
};

const formatBlockNode = (node: BlockElement) => {
  const { type } = node;
  const isList = LISTTYPES.includes(type as ListOptions);

  if (isList) {
    return `<ul>${node.children.map((n) => parseNode(n)).join('')}</ul>`;
  }

  return '';
};

// Parse a slate node and get a string ready for display in the editor
const parseNode = (node: Descendant): string => {
  if (Text.isText(node)) {
    return formatTextNode(node);
  }
  if (isLinkElement(node)) {
    return `[${Node.string(node)}](${node.url})`;
  }
  if (isBlockElement(node)) {
    return formatBlockNode(node);
  }
  if (isListemItemElement(node)) {
    return `<li>${node.children.map((n) => parseNode(n)).join('')}</li>`;
  }
  if (isTutorialEmbedElement(node)) {
    const n = node as TutorialEmbedElement; // for some reason it comes out as `never`
    return `::tutorial[${n.title}]{description="${n.description}" id="${n.id}"}`;
  }
  if (isChoiceGroupElement(node)) {
    const n = node as TutorialChoiceElement; // for some reason it comes out as `never`
    return [':::choice', ...n.children.map((n) => parseNode(n)), ':::'].join(
      '\n'
    );
  }
  if (isStepHeaderElement(node)) {
    const n = node as StepHeaderElement; // for some reason it comes out as `never`
    return `::step[${n.title}]{number="${n.number}"}`;
  }

  return node.children.map((n) => parseNode(n)).join('');
};

/**
 * Converts Slate-formatted JSON data to Markdown string ready
 * for the storage in the LocalizedContentVersion's properties
 * @param value Slate-formatted JSON data to convert
 * @returns string representation of the slate data in markdown
 */
export const serialize = (value: Descendant[]) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map(parseNode)
      // Join them all with line breaks denoting paragraphs.
      .join('\n')
  );
};

const parseListTokenStream = (ts: TokenStream): BulletedListElement | null => {
  // extract the list items from the token stream
  if (!Array.isArray(ts)) {
    return null;
  }

  const listItems = ts.filter((t) => {
    if (typeof t === 'string') {
      return false;
    }
    return t.type === 'list-item';
  }) as Token[]; // despite the filter, TS still thinks this could contain a string;

  const parsedListItems = listItems
    .map((li) => {
      const parsedItems = parseTokenStream(li.content)
        .flat()
        .filter(isNotNull)
        // filter out any stray spaces; link elements don't have .text, so keep them
        .filter((t) => isLinkElement(t) || t.text !== ' ');
      return {
        type: 'list-item',
        children: parsedItems,
      };
    })
    .filter(isNotNull);
  return {
    type: 'bulleted-list',
    // I tried many different things to get this to believe it is shaped correctly, but TS would give a different error each time.
    children: parsedListItems as any,
  };
};

const parseTutorialEmbedTokenStream = (
  ts: TokenStream
): TutorialEmbedElement | null => {
  if (!Array.isArray(ts)) {
    return null;
  }

  const titleToken = ts.find(
    (t) => typeof t !== 'string' && t.type === 'title'
  );
  const descriptionToken = ts.find(
    (t) => typeof t !== 'string' && t.type === 'description'
  );
  const idToken = ts.find((t) => typeof t !== 'string' && t.type === 'id');

  if (!titleToken || !descriptionToken || !idToken) {
    return null;
  }

  const title = (titleToken as any).content.find(
    (t: any) => typeof t === 'string'
  ) as string;
  const description = (descriptionToken as any).content.find(
    (t: any) => typeof t === 'string' && t !== 'description'
  ) as string;
  const id = (idToken as any).content.find(
    (t: any) => typeof t === 'string' && t !== 'id'
  ) as string;

  console.log(title, description, id);

  return {
    type: 'tutorial-embed',
    title: title || '',
    description: description || '',
    id: id || '',
    children: [{ type: 'paragraph', children: [{ text: '' }] }],
  };
};

const parseChoiceEmbedTokenStream = (
  ts: TokenStream
): TutorialChoiceElement | null => {
  if (!Array.isArray(ts)) {
    return null;
  }

  const choiceItems = ts.filter(
    (t) => typeof t !== 'string' && t.type === 'tutorial'
  ) as Token[];

  const parsedChoiceItems = choiceItems
    .map((item) => parseTutorialEmbedTokenStream(item.content))
    .filter(isNotNull);

  return {
    type: 'choice',
    children: parsedChoiceItems,
  };
};

const parseStepHeaderTokenStream = (ts: TokenStream) => {
  if (!Array.isArray(ts)) {
    return null;
  }

  const titleToken = ts.find(
    (t) => typeof t !== 'string' && t.type === 'title'
  );
  const numberToken = ts.find(
    (t) => typeof t !== 'string' && t.type === 'number'
  );

  const title = (titleToken as any).content.find(
    (t: any) => typeof t === 'string' && t !== 'title'
  ) as string;

  const number = (numberToken as any).content.find(
    (t: any) => typeof t === 'string' && t !== 'number'
  ) as string;

  return {
    type: 'step-header',
    title: title || '',
    number: number || '1',
    children: [{ type: 'paragraph', children: [{ text: '' }] }],
  };
};

/**
 * Recursive method to fully parse and convert to slate-flavored JSON
 * the results of calling Prism.tokenize(markdown: string)
 * @param ts A PrismJS TokenStream
 * @param bold Is the node bold
 * @param italic Is the node italic
 * @param link  Is the node a link
 * @returns An array of Slate-formatted JSON objects (formatted texts and links)
 */
const parseTokenStream = (
  ts: TokenStream,
  bold = false,
  italic = false,
  link = false
): (LinkElement | FormattedText | null)[] => {
  if (Array.isArray(ts)) {
    if (link && typeof ts[0] === 'string') {
      const [, text, url] = ts[0].match(/\[(.*)\]\((.*)\)/) ?? [];
      return [{ type: 'link', url, children: [{ text }] }];
    }
    return ts.map((t) => parseTokenStream(t, bold, italic)).flat();
  }
  if (isPrismToken(ts)) {
    // Prism creates "punctuation" and "tag" nodes which we don't need to keep,
    // for example, "**bold text**" becomes [{ type: "punctuation", content: "**" }, { content: "bold text" },...etc]
    // So return null here and we'll filter them out at the end
    if (ts.type === 'punctuation' || ts.type === 'tag') {
      return [null];
    }
    return parseTokenStream(
      ts.content,
      ts.type === 'bold' || bold,
      ts.type === 'italic' || italic
    );
  }
  return [{ text: ts, bold, italic }];
};

/**
 * Converts Markdown-formatted string data from the LocalizedContentVersion's
 * properties into Slate-formatted JSON data ready for the editor
 * @param value Markdown-formatted string to convert
 * @returns A representation of the data in slate-flavored JSON
 */
export const deserialize = (value: string) =>
  value.split('\n').map((text) => {
    // We use the PrismJS library to help convert Markdown-flavored strings
    // into _some kind_ of JSON, then fiddle with it to properly match the
    // JSON format that SlateJS expects to ingest.
    // This is based in part on the Markdown Preview example in the Slate docs:
    // https://www.slatejs.org/examples/markdown-preview
    const tokens = tokenize(text, languages.markdown);
    const children = tokens
      .map((t) => {
        if (isPrismToken(t)) {
          const bold = t.type === 'bold';
          const italic = t.type === 'italic';
          const link = t.type === 'url';
          const list = t.type === 'list';
          const embed = t.type === 'tutorial';
          const choice = t.type === 'choice';
          const stepHeader = t.type === 'step-header';

          if (stepHeader) {
            return parseStepHeaderTokenStream(t.content);
          }

          if (choice) {
            return parseChoiceEmbedTokenStream(t.content);
          }

          if (embed) {
            return parseTutorialEmbedTokenStream(t.content);
          }

          if (list) {
            return parseListTokenStream(t.content);
          }

          return parseTokenStream(t.content, bold, italic, link);
        }

        return [{ text: t }];
      })
      .flat()
      .filter(isNotNull); // filter out all the nulls from punctuation

    return {
      type: 'paragraph',
      children,
    };
  });

/**
 * Absolutely impenatrable regex to find and group Ozmo-specific formatting.
 * Finds groups that have these three parts (<*)(something else)(*>)
 *
 * (<\*\s?) - Find an opening "<*" with or without trailing space
 *
 * (((?!<\*\s?|\s?\*>).)*) - Find anything that doesn't contain a "<*" or "*>",
 *   plus or minus leading/trailing space, so we don't capture incorrectly
 *   if the string contains multiple bold tags
 *
 * (\s?\*>) - Find a closing "*>" with or without leading space
 */
const OZMO_FORMATTING_REGEX = /(<\*\s?)(((?!<\*\s?|\s?\*>).)*)(\s?\*>)/g;

/**
 * Converts text containing Ozmo's weirdo google sheets step text formatting for
 * bold text into standard markdown.
 * @param value A text string possibly containg Ozmo's weird <*bold text*> formatting
 * @returns A text string containing markdown-formatted text
 */
export const convertOzmoFormatToHTML = (ozmoFormattedText: string) =>
  ozmoFormattedText.replace(OZMO_FORMATTING_REGEX, '<b>$2</b>');

/**
 * Does a piece of string text contain any Ozmo-formatted tags such as <*bold*>
 * @param value String text to test if it contains any ozmo-formatted text
 * @returns True if it contains ozmo-formatted text, otherwise false
 */
export const hasOzmoFormattedText = (value: string) =>
  (value.match(OZMO_FORMATTING_REGEX)?.length ?? -1) > 0;
