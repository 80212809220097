import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import MultiSelect from 'components/multi-select';
import LoadingIndicator from 'components/loading-indicator';

import InProgress from '../statuses/in-progress';
import Complete from '../statuses/complete';
import Error from '../statuses/error';

import { Status } from './hooks';

const StyledDiv = styled.div`
  padding-top: 32px;
  & > div {
    width: 25%;
    margin-right: 16px;
  }
`;

type Props = {
  isLoading: boolean;
  title: string;
  slug: string;
  status: Status;
  languages: { value: string; chipLabel: string; label: string }[];
  handleSelectTarget: (languageIds: string[]) => void;
};

const TranslateContentEntryModalContent: FC<Props> = ({
  isLoading,
  title,
  slug,
  status,
  languages,
  handleSelectTarget,
}) => {
  if (isLoading) {
    return <LoadingIndicator message="Loading answer..." />;
  }

  if (!title) {
    return (
      <Typography>
        {
          'This answer cannot be translated because there is no English source. '
        }
      </Typography>
    );
  }

  if (status === Status.IN_PROGRESS) {
    return <InProgress />;
  }

  if (status === Status.COMPLETE) {
    return <Complete />;
  }

  if (status === Status.ERROR) {
    return <Error />;
  }

  return (
    <>
      <Typography>
        {`You are about to translate all text in `}
        <b>
          {title} ({slug})
        </b>
        {' from '}
        <b>English (en)</b>
        {' to your selected target languages.'}
      </Typography>
      <StyledDiv>
        <MultiSelect
          label="Target languages"
          options={languages}
          onSelectionChanged={handleSelectTarget}
        />
      </StyledDiv>
    </>
  );
};

export default TranslateContentEntryModalContent;
