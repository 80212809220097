import { FC } from 'react';
import { RenderElementProps, useSlateStatic, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { Stack, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { CustomTypes } from 'slate';

interface ChoiceGroupProps extends RenderElementProps {
  element: CustomTypes['Element'] & {
    type: 'choice';
  };
}

const ChoiceGroup: FC<ChoiceGroupProps> = ({
  attributes,
  children,
  element,
}) => {
  const editor = useSlateStatic() as ReactEditor;

  const handleAddChoice = () => {
    const path = ReactEditor.findPath(editor, element);
    const newChoice = {
      type: 'tutorial-embed',
      title: 'add a title',
      description: '',
      id: '',
      children: [],
    };
    Transforms.insertNodes(editor, newChoice as any, {
      at: [...path, children.length],
    });
  };

  const handleRemoveChoice = (index: number) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: [...path, index] });

    if (children.length === 1) {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  return (
    <div {...attributes}>
      <Stack
        spacing={2}
        contentEditable={false}
        border="1px solid var(--color-primary-base)"
        borderRadius={2}
        bgcolor="var(--color-primary-background)"
        paddingY={1}
        paddingX={2}
      >
        {children.map((child: any, index: number) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            {children[index]}
            <IconButton onClick={() => handleRemoveChoice(index)}>
              <RemoveCircleOutline />
            </IconButton>
          </div>
        ))}
        <IconButton onClick={handleAddChoice}>
          <AddCircleOutline />
        </IconButton>
      </Stack>
    </div>
  );
};

export default ChoiceGroup;
