import { useState, useCallback, useMemo, useEffect } from 'react';
import { isDefined } from 'services/utils/type-guards/generic';

import { useCurrentCategory } from '../answers/category';
import { extractTopicGroups } from '../answers/answers-list/utils';

export type SelectedReference = {
  id: number;
  categoryId: number;
  localizedContentEntryId?: Maybe<number>;
  title?: Maybe<string>;
  complete?: Maybe<boolean>;
};

export type ReferenceWithCategoryId = (
  | LocalizedCollectionReference
  | MissingLocalizedCollectionReference
) & {
  categoryId: number;
};

export const useContentTab = (toggleSidePanel: (id: number) => void) => {
  const {
    collectionId,
    categories,
    categoryId,
    category,
    refetchCollection,
  } = useCurrentCategory();

  const [selectedReferences, setSelectedReferences] = useState<
    SelectedReference[]
  >([]);
  const [showCategories, setShowCategories] = useState(true);
  const allReferences = useMemo(
    () =>
      categories?.reduce<ReferenceWithCategoryId[]>((acc, cur) => {
        return [
          ...acc,
          ...cur.contentEntries.map((c) => ({ ...c, categoryId: cur.id })),
        ];
      }, []),
    [categories]
  );

  const topicGrouped = extractTopicGroups(allReferences);
  const selectedReferencesWithData = useMemo(
    () =>
      selectedReferences
        .map((r) => allReferences.find((ref) => ref.id === r.id))
        .filter(isDefined),
    [selectedReferences, allReferences]
  );

  // Clear the list of selected answers:
  // - any time the selected category is changed, whether or not the collection also changes
  //     - otherwise we might accidentally take action on selected answers in other categories
  //       that are no longer visible
  // - any time the current collection changes, whether or not the category changes
  //     - if no category is selected (categoryId is undefined) and we navigate to a different
  //       collection - e.g. from a success toast - the categoryId won't change but we wan't to
  //       clear existing selections
  useEffect(() => {
    setSelectedReferences([]);
  }, [categoryId, collectionId]);

  const toggleShowCategories = useCallback(() => {
    setShowCategories((showCategories) => !showCategories);
  }, [setShowCategories]);

  const handleInfoClick = useCallback(
    (id: number) => {
      if (window.innerWidth < 1400) {
        setShowCategories(false);
      }
      toggleSidePanel(id);
    },
    [toggleSidePanel, setShowCategories]
  );

  const isReferenceSelected = useCallback(
    (referenceId: number, categoryId: number) =>
      !!selectedReferences.find(
        (ref) => ref.id === referenceId && ref.categoryId === categoryId
      ),
    [selectedReferences]
  );

  const handleSelectReference = useCallback(
    (id: number, categoryId: number) => {
      setSelectedReferences((cur) => {
        const existingReferenceIndex = cur.findIndex(
          (ref) => ref.id === id && ref.categoryId === categoryId
        );

        // if the reference is already selected, deselect it
        if (existingReferenceIndex > -1) {
          return cur.filter(
            (ref) => !(ref.id === id && ref.categoryId === categoryId)
          );
        }

        // otherwise add the reference to the selected lsit
        return [...cur, { id, categoryId }];
      });
    },
    []
  );

  const handleSelectAllReferences = useCallback(() => {
    setSelectedReferences(
      allReferences
        .filter(
          (ref) =>
            // select all the references within the category
            // or if the category is undefined because we're on the
            // "All answers" tab, select ALL references
            ref.categoryId === Number(categoryId) || categoryId === undefined
        )
        .map(
          ({ id, title, categoryId, complete, localizedContentEntryId }) => ({
            id,
            localizedContentEntryId,
            title,
            complete,
            categoryId,
          })
        )
    );
  }, [allReferences, categoryId]);

  const handleDeselectAllReferences = useCallback(() => {
    setSelectedReferences([]);
  }, []);

  return {
    handleInfoClick,
    handleDeselectAllReferences,
    handleSelectAllReferences,
    handleSelectReference,
    isLoading: false,
    isReferenceSelected,
    selectedReferences: selectedReferencesWithData,
    refetchCollection,
    allReferences: category?.contentEntries ?? allReferences,
    showCategories,
    toggleShowCategories,
    topicGrouped,
  };
};
