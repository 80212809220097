import { FC, useState } from 'react';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { FailureEntry } from '../translate-bulk/status';

type Props = {
  failures: FailureEntry[];
};

const CompleteWithFailures: FC<Props> = ({ failures }) => {
  const [isDisclosed, setIsDisclosed] = useState(false);

  return (
    <Stack spacing={2} alignItems="center" data-testid="translate-success">
      <svg
        width="250"
        height="250"
        viewBox="0 0 250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M107.453 93.133C106.905 93.2027 106.349 93.1295 105.838 92.9206C105.328 92.7117 104.879 92.3742 104.538 91.9408C104.196 91.5074 103.972 90.993 103.888 90.4475C103.804 89.902 103.863 89.3441 104.058 88.828L119.31 48.6045C119.516 48.0635 119.865 47.5886 120.32 47.2304C120.775 46.8723 121.318 46.6443 121.893 46.5708C122.467 46.4973 123.05 46.5811 123.581 46.8132C124.111 47.0453 124.568 47.417 124.904 47.8886L149.809 82.9682C150.129 83.4194 150.325 83.9456 150.381 84.4955C150.436 85.0454 150.348 85.6003 150.125 86.1061C149.902 86.6118 149.552 87.0511 149.108 87.3811C148.665 87.7111 148.144 87.9206 147.595 87.9891L107.453 93.133Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.1 64.8678V64.8973V64.8628M124.1 62.4028C123.994 62.4033 123.889 62.4099 123.785 62.4225C123.444 62.4673 123.116 62.5821 122.821 62.7597C122.527 62.9373 122.272 63.1739 122.074 63.4546C121.875 63.7352 121.736 64.054 121.667 64.3907C121.597 64.7275 121.598 65.075 121.669 65.4114C121.686 65.4877 121.492 64.9883 124.301 72.1075C124.356 72.2457 124.451 72.3642 124.574 72.4476C124.696 72.531 124.842 72.5754 124.99 72.5749H125.084C125.247 72.5547 125.399 72.4802 125.516 72.3634C125.632 72.2466 125.706 72.0941 125.726 71.9304C126.651 64.3266 126.589 64.8653 126.584 64.7964C126.571 64.4582 126.488 64.1263 126.341 63.8215C126.194 63.5167 125.985 63.2457 125.728 63.0252C125.276 62.6336 124.698 62.4179 124.1 62.4176V62.4028Z"
          fill="#DB8B03"
        />
        <path
          d="M126.446 79.564C126.34 79.5632 126.234 79.5698 126.129 79.5837C125.504 79.6578 124.932 79.9684 124.529 80.4516C124.127 80.9349 123.924 81.554 123.964 82.1818C124.004 82.8096 124.282 83.3983 124.743 83.827C125.203 84.2556 125.81 84.4916 126.439 84.4865C126.544 84.4864 126.65 84.479 126.754 84.4644C127.374 84.3854 127.941 84.0733 128.339 83.5914C128.738 83.1095 128.938 82.494 128.899 81.87C128.859 81.246 128.584 80.6603 128.129 80.2319C127.673 79.8036 127.072 79.5648 126.446 79.564Z"
          fill="#DB8B03"
        />
        <path
          d="M46.8384 147.111C45.7192 147.742 44.4733 148.115 43.1916 148.204C41.9098 148.292 40.6244 148.093 39.4292 147.622C38.234 147.151 37.159 146.418 36.2827 145.479C35.4064 144.539 34.7508 143.416 34.3637 142.191C34.251 141.836 34.0161 141.533 33.7008 141.335C33.3854 141.137 33.0101 141.058 32.6417 141.111L27.9456 141.827C27.7279 141.86 27.5196 141.939 27.3343 142.058C27.149 142.177 26.9908 142.334 26.87 142.518C26.7491 142.702 26.6683 142.91 26.6329 143.127C26.5974 143.344 26.6079 143.567 26.6639 143.78C27.7026 147.716 30.1586 151.129 33.5602 153.365C36.9618 155.6 41.0694 156.501 45.0942 155.893C46.0889 155.741 47.0679 155.5 48.0192 155.172"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.0252 141.054C59.2326 138.711 58.9369 136.351 58.1579 134.132C57.379 131.913 56.1347 129.887 54.5085 128.188C52.8823 126.489 50.9117 125.157 48.7289 124.282C46.546 123.407 44.2013 123.008 41.8519 123.113C41.6324 123.124 41.4177 123.181 41.2215 123.28C41.0253 123.379 40.8521 123.518 40.713 123.688C40.574 123.858 40.4721 124.056 40.414 124.268C40.356 124.48 40.343 124.701 40.3759 124.919L41.0918 129.615C41.1476 129.985 41.3341 130.323 41.6174 130.567C41.9008 130.812 42.2624 130.947 42.6367 130.948C43.9638 130.952 45.2722 131.262 46.4602 131.853C47.6483 132.445 48.6839 133.302 49.4868 134.359C50.2897 135.416 50.8381 136.644 51.0896 137.947C51.341 139.25 51.2887 140.593 50.9367 141.873"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.4104 149.917C46.4104 152.416 47.403 154.813 49.17 156.58C50.9369 158.347 53.3334 159.339 55.8322 159.339C58.331 159.339 60.7275 158.347 62.4944 156.58C64.2613 154.813 65.254 152.416 65.254 149.917C65.254 147.419 64.2613 145.022 62.4944 143.255C60.7275 141.488 58.331 140.496 55.8322 140.496C53.3334 140.496 50.9369 141.488 49.17 143.255C47.403 145.022 46.4104 147.419 46.4104 149.917Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.896 151.701C203.953 152.432 205.16 152.918 206.428 153.123C207.697 153.329 208.995 153.249 210.229 152.889C211.463 152.529 212.601 151.898 213.56 151.043C214.519 150.188 215.276 149.129 215.774 147.944C215.919 147.599 216.183 147.317 216.517 147.149C216.852 146.982 217.236 146.939 217.599 147.029L222.209 148.176C222.423 148.229 222.622 148.327 222.796 148.463C222.969 148.598 223.112 148.769 223.215 148.963C223.319 149.157 223.38 149.371 223.396 149.59C223.412 149.81 223.381 150.03 223.306 150.237C222.527 152.371 221.316 154.322 219.748 155.966C218.18 157.61 216.289 158.914 214.195 159.794C212.1 160.674 209.846 161.112 207.574 161.081C205.302 161.049 203.061 160.549 200.992 159.612"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.322 144.545C191.328 142.192 191.839 139.868 192.818 137.729C193.798 135.589 195.224 133.685 197.001 132.143C198.778 130.601 200.865 129.457 203.12 128.788C205.376 128.12 207.749 127.942 210.079 128.267C210.297 128.297 210.506 128.373 210.693 128.489C210.88 128.606 211.04 128.76 211.164 128.942C211.287 129.125 211.371 129.331 211.409 129.548C211.448 129.764 211.441 129.987 211.388 130.2L210.244 134.81C210.154 135.174 209.936 135.493 209.631 135.71C209.327 135.927 208.954 136.029 208.581 135.996C207.259 135.878 205.928 136.066 204.691 136.546C203.454 137.026 202.343 137.784 201.446 138.762C200.55 139.74 199.89 140.912 199.519 142.186C199.148 143.46 199.075 144.802 199.307 146.109"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.259 153.666C184.259 156.165 185.251 158.562 187.018 160.329C188.785 162.096 191.182 163.088 193.681 163.088C196.179 163.088 198.576 162.096 200.343 160.329C202.11 158.562 203.102 156.165 203.102 153.666C203.102 151.168 202.11 148.771 200.343 147.004C198.576 145.237 196.179 144.245 193.681 144.245C191.182 144.245 188.785 145.237 187.018 147.004C185.251 148.771 184.259 151.168 184.259 153.666Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.001 169.72C156.001 172.219 156.994 174.615 158.761 176.382C160.527 178.149 162.924 179.142 165.423 179.142C167.922 179.142 170.318 178.149 172.085 176.382C173.852 174.615 174.845 172.219 174.845 169.72C174.845 167.221 173.852 164.825 172.085 163.058C170.318 161.291 167.922 160.298 165.423 160.298C162.924 160.298 160.527 161.291 158.761 163.058C156.994 164.825 156.001 167.221 156.001 169.72Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M133.293 103.022V115.763H120.729V104.521"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.886 115.763H152.134V162.872H101.886V115.763Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.591 162.872H136.434V181.715H117.591V162.872Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.591 220.028V205.012"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M136.434 205.014V220.028"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.573 137.748C139.573 138.572 139.411 139.389 139.095 140.151C138.779 140.913 138.317 141.605 137.733 142.189C137.15 142.772 136.458 143.234 135.696 143.55C134.934 143.866 134.117 144.028 133.293 144.028H120.729C119.905 144.028 119.088 143.866 118.326 143.55C117.564 143.234 116.872 142.772 116.288 142.189C115.705 141.605 115.243 140.913 114.927 140.151C114.611 139.389 114.449 138.572 114.449 137.748V115.763H139.573V137.748Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144.783 181.715C145.787 181.716 146.776 181.957 147.667 182.418C148.558 182.88 149.326 183.548 149.906 184.367C150.486 185.186 150.861 186.132 151 187.126C151.139 188.12 151.038 189.133 150.705 190.079C148.971 194.978 145.762 199.218 141.519 202.217C137.276 205.217 132.207 206.827 127.011 206.827C121.815 206.827 116.747 205.217 112.504 202.217C108.261 199.218 105.051 194.978 103.318 190.079C102.984 189.132 102.882 188.119 103.021 187.125C103.16 186.131 103.535 185.184 104.116 184.365C104.696 183.546 105.464 182.877 106.356 182.416C107.248 181.955 108.237 181.715 109.241 181.715H144.783Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M152.877 132.756C152.632 133.4 152.516 134.085 152.536 134.773C152.557 135.46 152.712 136.137 152.995 136.765C153.277 137.393 153.68 137.959 154.181 138.43C154.682 138.902 155.271 139.27 155.915 139.514L162.766 142.114C165.102 143 167.695 142.922 169.974 141.896C172.253 140.871 174.032 138.982 174.918 136.646L176.03 133.708C176.47 132.552 176.677 131.32 176.64 130.083C176.603 128.846 176.323 127.628 175.815 126.5C175.307 125.371 174.582 124.354 173.681 123.505C172.78 122.657 171.721 121.995 170.564 121.556L164.69 119.327C163.132 118.737 161.403 118.789 159.884 119.473C158.365 120.157 157.18 121.416 156.589 122.973L152.877 132.756Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.7161 132.801C99.5495 133.468 99.2532 134.095 98.8441 134.648C98.435 135.2 97.921 135.666 97.3316 136.02C96.7422 136.373 96.0889 136.607 95.409 136.709C94.7292 136.81 94.036 136.776 93.3693 136.609L86.2599 134.84C83.8356 134.234 81.7515 132.689 80.4659 130.546C79.1802 128.404 78.7982 125.838 79.4038 123.413L80.1664 120.368C80.7724 117.944 82.3163 115.86 84.4588 114.574C86.6012 113.288 89.1666 112.906 91.5907 113.512L97.6841 115.035C98.4852 115.235 99.239 115.591 99.9024 116.083C100.566 116.574 101.126 117.192 101.55 117.9C101.975 118.608 102.255 119.393 102.376 120.21C102.497 121.027 102.456 121.86 102.255 122.661L99.7161 132.801Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.5947 167.145C72.5947 169.644 73.5874 172.04 75.3543 173.807C77.1212 175.574 79.5177 176.567 82.0165 176.567C84.5153 176.567 86.9118 175.574 88.6787 173.807C90.4457 172.04 91.4383 169.644 91.4383 167.145C91.4383 164.646 90.4457 162.25 88.6787 160.483C86.9118 158.716 84.5153 157.723 82.0165 157.723C79.5177 157.723 77.1212 158.716 75.3543 160.483C73.5874 162.25 72.5947 164.646 72.5947 167.145Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.7375 39.4449L164.986 28.9141L173.26 98.2517L85.0117 108.783L76.7375 39.4449Z"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.0842 170.146L57.4263 159.206"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.2515 149.578L78.1271 158.576"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.335 152.458L170.589 160.284"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M174.485 172.304L191.238 162.769"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M158.092 140.333L158.013 163.9"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.582 161.838L170.65 141.551"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M95.1602 136.742L90.1763 162.429"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.1272 158.577L83.0251 133.332"
          stroke="#DB8B03"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Stack spacing={1} alignItems="flex-start">
        <Typography variant="h4" component="p">
          {'Uh oh! Some translations could not be completed. '}
        </Typography>
        <Button
          variant="text"
          color="primary"
          endIcon={isDisclosed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onClick={() => setIsDisclosed((isDisclosed) => !isDisclosed)}
        >
          {`Incomplete answers (${failures.length})`}
        </Button>
        <Collapse in={isDisclosed}>
          <Stack spacing={1}>
            {failures.map((failure, index) => (
              <Typography key={index}>
                {failure.title} <strong>({failure.slug})</strong>
              </Typography>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default CompleteWithFailures;
