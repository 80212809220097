export type BaseEvent = {
  action: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    picture: string;
  };
  createdAt: string;
  contentEntryId?: number;
  localizedContentEntryId?: number;
};

type AttributeDetails = {
  id: number;
  name: string;
  type: string;
  action: '+' | '-';
};

export type AttributesEvent = BaseEvent & {
  action: 'change_attributes';
  details: AttributeDetails[];
};

export type AttributeAddedEvent = BaseEvent & {
  action: 'add_attribute';
  details: AttributeDetails;
};

export type AttributeDeletedEvent = BaseEvent & {
  action: 'delete_attribute';
  details: AttributeDetails;
};

export type AttributeEvent = AttributeAddedEvent | AttributeDeletedEvent;

export type ContentEvent = BaseEvent & {
  action: 'change_content';
  details: {
    key: string;
    before?: any;
    after?: any;
  };
};

export type ContentAddedEvent = ContentEvent & {
  details: {
    key: string;
    after: any;
  };
};

export type ContentRemovedEvent = ContentEvent & {
  details: {
    key: string;
    before: any;
  };
};

type Coordinate =
  | {
      x: number;
    }
  | {
      y: number;
    };

export type IndicatorChangedEvent = ContentEvent & {
  details: {
    key: string;
    before: Coordinate;
    after: Coordinate;
  };
};

export type HistoryEvent =
  | BaseEvent
  | AttributesEvent
  | AttributeEvent
  | ContentEvent;

export const isContentEvent = (event: HistoryEvent): event is ContentEvent =>
  event.action === 'change_content';

export const isContentAddedEvent = (
  event: HistoryEvent
): event is ContentAddedEvent =>
  isContentEvent(event) &&
  event.details.before === undefined &&
  event.details.after !== undefined;

export const isContentRemovedEvent = (
  event: HistoryEvent
): event is ContentRemovedEvent =>
  isContentEvent(event) &&
  event.details.after === undefined &&
  event.details.before !== undefined;

export const isContentChangedEvent = (
  event: HistoryEvent
): event is ContentEvent =>
  isContentEvent(event) &&
  event.details.before !== undefined &&
  event.details.after !== undefined;

export const isIndicatorChangedEvent = (
  event: HistoryEvent
): event is IndicatorChangedEvent =>
  isContentEvent(event) &&
  event.action === 'change_content' &&
  event.details.key.includes('.indicator') &&
  event.details.before &&
  event.details.after;

export const isAttributesEvent = (
  event: HistoryEvent
): event is AttributesEvent => event.action === 'change_attributes';

export const isAttributeAddedEvent = (
  event: HistoryEvent
): event is AttributeAddedEvent => event.action === 'add_attribute';

export const isAttributeDeletedEvent = (
  event: HistoryEvent
): event is AttributeDeletedEvent => event.action === 'delete_attribute';

export const isAttributeEvent = (
  event: HistoryEvent
): event is AttributeEvent =>
  isAttributeAddedEvent(event) || isAttributeDeletedEvent(event);

// Schema types
export type ContentTypePrimitiveField = {
  name: string;
  type: string;
  editor: {
    displayName: string;
  };
};

export type ContentTypeObjectField = {
  name: string;
  type: 'Object';
  fields: Field[];
  editor: {
    displayName: string;
  };
};

export type ContentTypeArrayField = {
  name: string;
  type: 'Array';
  items: Field;
  editor: {
    displayName: string;
  };
};

export type Field =
  | ContentTypePrimitiveField
  | ContentTypeObjectField
  | ContentTypeArrayField;

export type Schema = {
  fields: Field[];
};

export const isArrayField = (field: Field): field is ContentTypeArrayField =>
  field.type === 'Array';

export const isObjectField = (field: Field): field is ContentTypeObjectField =>
  field.type === 'Object';

// Field display name types
export type DisplayNameSingle = { key: string; singular: string };
export type DisplayNameExtended = DisplayNameSingle & {
  plural: string;
  withIndex: string;
};
export type DisplayName = DisplayNameSingle | DisplayNameExtended;

export const isDisplayNameSingle = (
  displayName: DisplayName
): displayName is DisplayNameSingle => !('plural' in displayName);

export const isDisplayNameExtended = (
  displayName: DisplayName
): displayName is DisplayNameExtended => 'plural' in displayName;
