import { Link, LinkOff } from '@mui/icons-material';
import { MouseEventHandler, useCallback } from 'react';
import { useSlate } from 'slate-react';
import { Transforms } from 'slate';

import { insertLink, isLinkActive, unwrapLink } from '../plugins';
import { BlockOptions, MarkOptions } from '../types';
import { toggleMark, toggleBlock, isMarkActive, isBlockActive } from '../utils';

const getColors = (isActive: boolean) => {
  const color = isActive ? 'var(--color-neutral-one)' : 'inherit';
  const backgroundColor = isActive ? 'var(--color-neutral-seven)' : undefined;
  return { color, backgroundColor };
};

export const useLinkButton = () => {
  const editor = useSlate();
  const isActive = isLinkActive(editor);
  const label = isActive ? 'Remove link' : 'Add link';
  const icon = isActive ? <LinkOff /> : <Link />;
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    if (isActive) {
      unwrapLink(editor);
    } else {
      const url = window.prompt('Enter the URL of the link:');
      if (!url) return;
      insertLink(editor, url);
    }
  };

  return { icon, color, backgroundColor, label, handleClick };
};

export const useFormatButton = (format: MarkOptions) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      toggleMark(editor, format);
    },
    [editor, format]
  );

  return { color, backgroundColor, handleClick };
};

export const useBlockButton = (format: BlockOptions) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      toggleBlock(editor, format);
    },
    [editor, format]
  );

  return { color, backgroundColor, handleClick };
};

export const useTutorialEmbedButton = () => {
  const editor = useSlate();
  const format = 'tutorial-embed';
  const isActive = isBlockActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      Transforms.insertNodes(editor, [
        {
          type: 'tutorial-embed',
          title: 'add a title',
          description: '',
          id: '',
          children: [],
        },
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ]);
    },
    [editor]
  );

  return { color, backgroundColor, handleClick };
};

export const useTutorialChoiceGroup = () => {
  const editor = useSlate();
  const format = 'choice';
  const isActive = isBlockActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      // eslint-disable-next-line no-sparse-arrays
      Transforms.insertNodes(editor, [
        {
          type: 'paragraph',
          children: [
            {
              text: ':::choice',
            },
          ],
        },
        {
          type: 'tutorial-embed',
          title: 'add a title',
          description: '',
          id: '',
          children: [],
        },
        {
          type: 'paragraph',
          children: [
            {
              text: ':::',
            },
          ],
        },
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ] as any);
    },
    [editor]
  );

  return { color, backgroundColor, handleClick };
};

export const useStepHeaderButton = () => {
  const editor = useSlate();
  const { color, backgroundColor } = getColors(false);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      Transforms.insertNodes(editor, [
        {
          type: 'step-header',
          number: '1',
          title: '',
          children: [{ type: 'paragraph', children: [{ text: '' }] }],
        },
        { type: 'paragraph', children: [{ text: '' }] },
      ]);
    },
    [editor]
  );

  return { color, backgroundColor, handleClick };
};
