import { FC, useCallback, ChangeEvent } from 'react';
import { ReactEditor, useSlateStatic, RenderElementProps } from 'slate-react';
import { Transforms, CustomTypes } from 'slate';
import { Stack, TextField } from '@mui/material';

interface StepHeaderProps extends RenderElementProps {
  element: CustomTypes['Element'] & {
    type: 'step-header';
    title: string;
    number: string;
  };
}

const StepHeader: FC<StepHeaderProps> = ({ attributes, element }) => {
  const editor = useSlateStatic() as ReactEditor;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        { [e.target.name]: e.target.value },
        { at: path }
      );
    },
    [editor, element]
  );

  return (
    <div {...attributes} style={{ flex: 1 }}>
      <Stack direction="row" paddingY={1} paddingX={2} contentEditable={false}>
        <TextField
          variant="standard"
          sx={{ flex: '0 0 2rem' }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.25rem',
              fontWeight: '500',
            },
          }}
          name="number"
          value={element.number}
          onChange={handleChange}
          placeholder="Number"
        />
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.25rem',
              fontWeight: 500,
            },
          }}
          name="title"
          value={element.title}
          onChange={handleChange}
          placeholder="Title"
        />
      </Stack>
    </div>
  );
};

export default StepHeader;
