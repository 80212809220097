import ActionChip from 'components/actionable-chip';
import { CircularProgress } from '@mui/material';

import {
  AttributeEvent,
  isAttributeAddedEvent,
  isAttributeDeletedEvent,
} from '../types';

import { useAttributeLabel } from './use-attribute-label';

type Props = {
  historyEvent: AttributeEvent;
};

const AttributeChangeDetails = ({ historyEvent }: Props) => {
  const { isLoading, label } = useAttributeLabel(historyEvent);

  if (isLoading) return <CircularProgress size="1em" />;

  if (isAttributeAddedEvent(historyEvent)) {
    return (
      <ActionChip
        label={label || 'Unidentified attribute'}
        color="addedAttributeChip"
        sx={{ borderColor: 'transparent' }}
      />
    );
  }

  if (isAttributeDeletedEvent(historyEvent)) {
    return (
      <ActionChip
        label={label || 'Unidentified attribute'}
        color="removedAttributeChip"
        sx={{ borderColor: 'transparent' }}
      />
    );
  }

  return null;
};

export default AttributeChangeDetails;
