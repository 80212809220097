/**
 * Wraps a controlled MUI Autocomplete component for selecting a single Collection.
 *
 * "Controlled" here meaning the Autocomplete's `value` property is externally managed via the
 * `selectedCollectionId` prop. The `onSelectionChange` callback is invoked whenever the
 * Autocomplete's selected value changes, but it is up to the controlling component to propagate
 * that change to the `selectedCollectionId`.
 *
 * Customizations to the Autocomplete include:
 * - Input text field (`renderInput`) adds icon in start adornment position and sets placeholder text
 * - Option list items (`renderOption`) display both the name and ID of the option, and add a
 *   `key={id}` attribute to the `<li>` for referential stability
 * - Option comparison (`isOptionEqualToValue`) uses the `id` attribute instead of the default strict equality
 * - Option labels (`getOptionLabel`) use the `name` attribute instead of the default `label`
 * - Option filtering (`filterOptions`) will match on the `id` attribute if the text input is a positive integer
 *   in addition to the default subtring matching on option labels
 *
 * Many of these changes are based on the AutocompleteMultiselect component, but this component
 * doesn't allow selecting multiple values and requires external control.
 */
import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  FilterOptionsState,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { FC, useMemo } from 'react';
import Fuse from 'fuse.js';

// Component input properties
// NOTE using null instead of undefined is INTENTIONAL. Autocomplete components are considered
// 'controlled' if their value is set to something other than 'undefined' during their first
// render. If the initial value is 'undefined' then they are 'uncontrolled' and it is undefined
// behavior to try to manually set their 'value' prop afterward.
type Props = {
  /** ID of current collection, to exclude it as a selectable option. */
  originCollectionId: number;
  /** ID of selected collection, or null if none selected */
  selectedCollectionId: number | null;
  /** Called with ID of selected collection */
  onSelectionChange: (id: number | null) => void;
} & Omit<
  AutocompleteProps<CollectionOption, false, false, false>,
  'options' | 'value' | 'onChange' | 'renderInput'
>;

// Type of options given to the autocomplete component
export type CollectionOption = {
  id: number;
  name: string;
  /**
   * pre-computed stringified version of `.id`, used for ID-based filtering in a
   * custom `Autocomplete.filterOptions` function. That function is called on every
   * keystroke to update available options, and we would call `.id.toString()` for
   * every option for every update. Probably premature optimization.
   */
  idAsString: string;
};

const TEXT_FIELD_PLACEHOLDER = 'Search by collection ID or collection name';

// This is copied from the AutocompleteMultiselect component and is used to compare
// autocomplete options by ID instead of the default strict equality
const compareOption = (
  option: CollectionOption,
  value: CollectionOption | null
) => option.id === value?.id;

// Used for the Autocomplete's `renderInput` prop.
const renderInputTextField = (params: AutocompleteRenderInputParams) => (
  <TextField
    {...params}
    variant="outlined"
    placeholder={TEXT_FIELD_PLACEHOLDER}
    // this adds the little magnifying glass icon to the start of the field
    InputProps={{
      ...params.InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    // this adds HTML attributes to the <input/> element
    inputProps={{ ...params.inputProps, 'aria-label': 'Collection' }}
  />
);

// Used for Autocomplete's `renderOption` prop.
// Our collections can have non-unique names. This renders both the name and ID, and
// adds a key prop to the list item set to the collection ID to make the components
// stable. Note we also need to set getOptionLabel so the input text field will displays
// the selected collection name.
const renderOptionListItem = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: CollectionOption
  // state: AutocompleteRenderOptionState
  // ownerState: AutocompleteOwnerState
) => {
  // typography cargo-culted from:
  // CollectionTableRow
  // LocalizedReference
  return (
    <li {...props} key={option.idAsString}>
      <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {option.name}
      </Typography>
      <Typography
        color="var(--color-neutral-six)"
        fontSize="0.875rem"
        display="inline"
        marginLeft="3px"
      >{`(#${option.id})`}</Typography>
    </li>
  );
};

const convertToOption = ({ id, name }: CollectionModel): CollectionOption => ({
  id,
  name,
  idAsString: id.toString(),
});

/**
 * Wraps a MUI Autocomplete component for selecting a single Collection. Loads available
 * collections internally and uses a callback property to indicate selection. The props
 * type allows passing through most of Autocomplete's props, e.g. `sx` or `classNames`.
 */
export const CollectionSearchInput: FC<Props> = ({
  originCollectionId,
  selectedCollectionId,
  onSelectionChange,
  ...extraProps
}) => {
  const api = useOzmoApiService();
  const { all, isLoading } = api.Collection.getAll(undefined, undefined, {
    // by default 'getAll' only gets a page of 25 results
    perPage: 1000,
  });

  const fuse = useMemo(() => {
    return new Fuse(all, {
      keys: ['id', 'name'],
      includeScore: true,
      shouldSort: true,
      minMatchCharLength: 2,
      threshold: 0.4,
    });
  }, [all]);

  const filterOptions = (
    options: CollectionOption[],
    state: FilterOptionsState<CollectionOption>
  ) => {
    const fuseResults = state.inputValue
      ? fuse.search(state.inputValue)
      : all.map((item) => ({ item, score: 0 }));
    const fuseOptions = fuseResults.map((result) =>
      convertToOption(result.item)
    );
    return fuseOptions;
  };

  const availableOptions = all?.map(convertToOption) ?? [];

  const selectedOption =
    selectedCollectionId === null
      ? null
      : availableOptions.find((it) => it.id == selectedCollectionId);

  const handleChange = async (
    event: React.SyntheticEvent,
    value: CollectionOption | null
  ) => {
    onSelectionChange(value?.id ?? null);
  };

  return (
    <Autocomplete
      // state
      loading={isLoading}
      options={availableOptions}
      value={selectedOption}
      // callbacks
      onChange={handleChange}
      renderInput={renderInputTextField}
      renderOption={renderOptionListItem}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => option.id === originCollectionId}
      isOptionEqualToValue={compareOption}
      filterOptions={filterOptions}
      // style
      fullWidth
      autoHighlight
      autoSelect
      noOptionsText="No results found."
      {...extraProps}
    />
  );
};
