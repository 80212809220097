import { Box, Typography } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';
import MarkdownTextWithNewlines from 'components/markdown-text-with-newlines';

import { getDisplayNames, getPathText, isMedia, parsePath } from '../util';
import {
  ContentEvent,
  isContentChangedEvent,
  isIndicatorChangedEvent,
} from '../types';

const formatContentText = (text: string) => (
  <MarkdownTextWithNewlines
    text={String(text) === '' ? '_empty_' : `${text}`}
  />
);

const ContentChangeDetails = ({
  historyEvent,
}: {
  historyEvent: ContentEvent;
}) => {
  const path = parsePath(historyEvent.details.key);

  const api = useOzmoApiService();
  const { contentEntry } = useCurrentLocalizedContentEntry();
  const contentType = api.ContentType.get({ id: contentEntry?.contentTypeId });

  const fieldDisplayNames = getDisplayNames(
    path,
    contentType.data.contentSchema.fields
  );

  const label = getPathText(fieldDisplayNames);

  // Don't show non-content, indicator, or media change details
  if (
    !isContentChangedEvent(historyEvent) ||
    isIndicatorChangedEvent(historyEvent) ||
    isMedia(fieldDisplayNames[fieldDisplayNames.length - 1])
  ) {
    return <Typography>{label}</Typography>;
  }

  return (
    <>
      <Typography>{label}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography component="span">
          {formatContentText(historyEvent.details.before as string)}
        </Typography>
        <Typography component="span" sx={{ mx: '1em' }}>
          <ArrowRightAlt />
        </Typography>
        <Typography component="span">
          {formatContentText(historyEvent.details.after as string)}
        </Typography>
      </Box>
    </>
  );
};

export default ContentChangeDetails;
